import React, { Fragment} from "react";
import Alert from '@material-ui/lab/Alert';

export default function Error({errors}) {

    return (
        <Fragment>
            {
                errors?.map(error => 
                    <Alert key={error?.id} className="mb-3" severity="error">
                        {error?.message}
                    </Alert>
                ) 
            }
        </Fragment>
    )

}
import React, { forwardRef } from "react";
import { 
    Typography,
    IconButton
} from '@material-ui/core';
import PropertyEditor from '../../../components/PropertyEditor/';
import { KeyboardBackspace } from 'mdi-material-ui';
import { NavLink } from 'react-router-dom';

const ForwardNavLink = forwardRef((props, ref) => (
    <NavLink {...props} innerRef={ref} />
))

const EditProperty = (props) => {
    return (
        <>
            <div className="d-flex align-items-center mb-3">
                <IconButton size="small" className="me-1" component={ForwardNavLink} to={"/dashboard/listings"}>
                    <KeyboardBackspace/>
                </IconButton>
                <Typography variant="h6" className="mb-0">Edit Property</Typography>
            </div>
            <PropertyEditor
                property_id={props.match.params.property_id}
            />
        </>
    )
}
export default EditProperty; 
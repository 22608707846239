import React from "react";
/*
import Uploader from './Wizard/components/Uploader';
import MediaLibrary from './components/MediaLibrary/MediaLibrary';
import RoomLayout from './components/RoomLayout/RoomLayout';
*/

const Home = () => {
    return (
        <div className="container mt-3">
            <div className="row">
                <div className="col-12 text-center">
                    Front End is under construction.
                </div>
            </div>
            {/*
            <div className="row">
                <div className="col-12">
                    <RoomLayout/>
                </div>
            </div>

            <div className="row">
                <div className="col-12">
                    <Uploader/>
                </div>
            </div>
            <div className="row">
                <div className="col-12">
                    <MediaLibrary
                        property_id={"6057aca37b55bf34e8964f13"}
                        mode="edit"
                    />
                </div>
            </div>
            */}
        </div>
    )
}
export default Home; 
import React, { useEffect } from "react";
import { 
    Button,
    TextField,
    MenuItem,
    InputAdornment,
    LinearProgress
} from '@material-ui/core';
import { useForm, Controller } from "react-hook-form";
import { useSelector } from 'react-redux';
import Selector from './../components/Selector';
import options from './options.json'

const Step5 = ({handleBackCallback, handleNextCallback, submitting, mode}) => {

    // misc hooks
    const { handleSubmit, control, errors, setValue, getValues  } = useForm();

    // redux
    const { wizard } = useSelector(state => state);

    // methods
    const handleNextStep = async (form) => {
        handleNextCallback(form);
    }

    const handleBackStep = async () => {
        const payload = {
            water: getValues("water"),
            sewer: getValues("sewer"),
            special_designations: getValues("special_designations"),
            waterfront: getValues("waterfront"),
            age: getValues("age"),
            square_footage: getValues("square_footage")
        };
        handleBackCallback(payload);
    }

    useEffect(() => {
        setValue("water", wizard?.technical?.water ? wizard?.technical?.water : '');
        setValue("sewer", wizard?.technical?.sewer ? wizard?.technical?.sewer : '');
        setValue("special_designations", wizard?.technical?.special_designations ? wizard?.technical?.special_designations : '');
        setValue("waterfront", wizard?.technical?.waterfront ? wizard?.technical?.waterfront : '');
        setValue("age",  (wizard?.technical?.age !== null && wizard?.technical?.age !== undefined) ? wizard?.technical?.age : '');
        setValue("square_footage", (wizard?.technical?.square_footage !== null && wizard?.technical?.square_footage !== undefined) ? wizard?.technical?.square_footage : '');
    },[setValue,wizard?.technical]);

    return (
        <div className="row">
            <div className="col-12">
                <form onSubmit={handleSubmit(handleNextStep)} className="w-100">
                    <Selector
                        name="water"
                        className={"mb-3"}
                        label="Water"
                        control={control}
                        defaultValue={""}
                        variant="outlined"
                        size="small"
                        fullWidth={true}
                        rules={{ 
                            required: "This field is required." 
                        }}
                        errors={errors}
                    >
                        <MenuItem value="">Please select</MenuItem>
                        {
                            options.technical.water.map((option,i) => {
                                return (
                                    <MenuItem value={option.field} key={i}>{option.value}</MenuItem>
                                )
                            })
                        }
                    </Selector>
                    <Selector
                        name="sewer"
                        className={"mb-3"}
                        label="Sewer"
                        control={control}
                        defaultValue={""}
                        variant="outlined"
                        size="small"
                        fullWidth={true}
                        rules={{ 
                            required: "This field is required." 
                        }}
                        errors={errors}
                    >
                        <MenuItem value="">Please select</MenuItem>
                        {
                            options.technical.sewer.map((option,i) => {
                                return (
                                    <MenuItem value={option.field} key={i}>{option.value}</MenuItem>
                                )
                            })
                        }
                    </Selector>
                    <Selector
                        name="special_designations"
                        className={"mb-3"}
                        label="Special Designations"
                        control={control}
                        defaultValue={""}
                        variant="outlined"
                        size="small"
                        fullWidth={true}
                        rules={{ 
                            required: "This field is required." 
                        }}
                        errors={errors}
                    >
                        <MenuItem value="">Please select</MenuItem>
                        {
                            options.technical.special_designations.map((option,i) => {
                                return (
                                    <MenuItem value={option.field} key={i}>{option.value}</MenuItem>
                                )
                            })
                        }
                    </Selector>
                    <Selector
                        name="waterfront"
                        className={"mb-3"}
                        label="Waterfront"
                        control={control}
                        defaultValue={""}
                        variant="outlined"
                        size="small"
                        fullWidth={true}
                        rules={{ 
                            required: "This field is required." 
                        }}
                        errors={errors}
                    >
                        <MenuItem value="">Please select</MenuItem>
                        {
                            options.technical.waterfront.map((option,i) => {
                                return (
                                    <MenuItem value={option.field} key={i}>{option.value}</MenuItem>
                                )
                            })
                        }
                    </Selector>
                    <Controller
                        as={
                            <TextField 
                                label="Approximate Age"
                                autoComplete="new-password"
                                fullWidth={true}
                                size="small"
                                variant="outlined"
                                error={errors.age && true}
                                helperText={ errors?.age?.message }
                                className="mb-3"
                                InputProps={{
                                    endAdornment: <InputAdornment position="end">Years</InputAdornment>,
                                    inputProps: { 
                                        max: 300, min: 0 
                                    }
                                }}
                            />
                        }
                        name="age"
                        control={control}
                        defaultValue={""}
                        rules={{ 
                            required: "This field is required."
                        }}
                    />
                    <Controller
                        as={
                            <TextField 
                                label="Square Footage"
                                autoComplete="new-password"
                                fullWidth={true}
                                size="small"
                                variant="outlined"
                                error={errors.square_footage && true}
                                helperText={ errors?.square_footage?.message }
                                className="mb-3"
                                InputProps={{
                                    endAdornment: <InputAdornment position="end">Sq. Feet</InputAdornment>,
                                    inputProps: { 
                                        max: 999999, min: 0 
                                    }
                                }}
                            />
                        }
                        name="square_footage"
                        control={control}
                        defaultValue={""}
                        rules={{ 
                            required: "This field is required."
                        }}
                    />
                    <div className="d-flex align-items-center">
                        {
                            mode === "wizard" ? (
                                <>
                                    <Button 
                                        disabled={submitting}
                                        variant="outlined"
                                        color="primary"
                                        disableElevation
                                        size="small"
                                        onClick={() => handleBackStep()}
                                        >
                                        Previous
                                    </Button>
                                    <Button 
                                        disabled={submitting}
                                        className="mx-3"
                                        variant="outlined"
                                        color="primary"
                                        disableElevation
                                        size="small"
                                        type="submit"
                                        >
                                        Save & Continue
                                    </Button>
                                </>
                            ) : (
                                <Button 
                                    disabled={submitting}
                                    variant="outlined"
                                    color="primary"
                                    disableElevation
                                    className="me-3"
                                    size="small"
                                    type="submit">
                                    Save Changes
                                </Button>
                            )
                        }
                        { submitting && <LinearProgress style={{width: '30px'}}/> }
                    </div>
                </form>
            </div>
        </div>
    )
}
export default Step5;
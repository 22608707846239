export const SetAddress = (data) => async (dispatch) => {
    dispatch({
        type: 'SET_ADDRESS',
        payload: data
    })
}

export const SetState = (data) => async (dispatch) => {
    dispatch({
        type: 'SET_STATE',
        payload: data
    })
}

export const SetType = (data) => async (dispatch) => {
    dispatch({
        type: 'SET_TYPE',
        payload: data
    })
}

export const SetLegalName = (data) => async (dispatch) => {
    dispatch({
        type: 'SET_LEGAL_NAME',
        payload: data
    })
}

export const ResetWizard = (data) => async (dispatch) => {
    dispatch({
        type: 'RESET_WIZARD',
    })
}

export const SetId = (data) => async (dispatch) => {
    dispatch({
        type: 'SET_ID',
        payload: data
    })
}

export const Restore = (data) => async (dispatch) => {
    dispatch({
        type: 'RESTORE',
        payload: data
    })
}
import React, { useState, useEffect } from "react";
import { 
    Dialog,
    IconButton 
} from '@material-ui/core';
import { Route, Redirect } from "react-router-dom";
import { Stepper } from './index';
import { Close } from 'mdi-material-ui';
import styles from './Wizard.module.scss';
import classNames from "classnames";
import { ResetWizard } from './../store/actions/wizard.js';
import { useSelector, useDispatch } from 'react-redux';
//import ConfirmationDialog from './components/ConfirmationDialog';
import LoadingScreen from './../components/LoadingScreen';

const Wizard = (props) => {

    // state
    const [prevPath, setPrevPath] = useState('');

    // redux
    const { app, wizard } = useSelector(state => state);
    const dispatch = useDispatch();

    // lifecycles
    useEffect(() => {
        setPrevPath(props?.history?.location?.state?.prevPath)
    },[])

    const triggerPrompt = () => {
        onConfirm()
        //setPromptActive(true);
    }

    const onConfirm = () => {
        props.history.push(prevPath ? prevPath : '/');
        dispatch(ResetWizard());
    }
    /*
    const onCancel = () => {
        setPromptActive(false);
    }
    const onConfirmAndSave = () => {
        props.history.push('/');
    }
    */
    if(app.loading) return <LoadingScreen/>;
    if(!app.loading && !app.loggedin) return <Redirect to="/"/>

    return (
        <Dialog fullScreen open={true}>
            {/*
                show_prompt && (
                    <ConfirmationDialog
                        onConfirm={onConfirm}
                        onCancel={onCancel}
                        onConfirmAndSave={onConfirmAndSave}
                    />
                )
            */}
            <div className={classNames(styles['wizard'], 'container-fluid')}>
                <div className={classNames(styles['close-button'])}>
                    <IconButton aria-label="exit" onClick={triggerPrompt}>
                        <Close />
                    </IconButton>
                    <span className={classNames(styles['label'])}>Close</span>
                </div>
                <div className="row justify-content-center">
                    <div className="col-12 col-xxl-5 col-xl-7 col-lg-9 col-md-10 mt-3">
                        <Route exact path={'/wizard/:step'} render={(props) => <Stepper {...props} />} />
                    </div>
                </div>
            </div>
        </Dialog>
    )
}
export default Wizard; 
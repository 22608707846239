import React, { forwardRef } from "react";
import styles from './Dashboard.module.scss';
import classNames from "classnames";
import { NavLink } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { 
    List,
    ListItem,
    ListItemText,
    Button
} from '@material-ui/core';

const ForwardNavLink = forwardRef((props, ref) => (
    <NavLink {...props} innerRef={ref} />
))

const Sidebar = () => {

    // redux
    const redux = useSelector(state => state);
    const dispatch = useDispatch();

    return (
        <div className={classNames(styles['sidebar-wrapper'], 'col-md-3 d-none d-md-block')}>
            {/*
                <Button 
                    variant="contained" 
                    disableElevation
                    color="primary"
                    className="w-100 mb-1"
                    component={ForwardNavLink}
                    to={"/wizard/" }
                >Add New Property</Button>
            */}
            <List
                dense
                component="nav"
                >
                <ListItem
                    exact
                    activeClassName={styles.selected}
                    button
                    component={ForwardNavLink}
                    to={"/dashboard/listings" }
                >
                    <ListItemText primary="My Properties" />
                </ListItem>
                <ListItem
                    exact
                    activeClassName={styles.selected}
                    button
                    component={ForwardNavLink}
                    to={"/dashboard/settings" }
                >
                    <ListItemText primary="Profile" />
                </ListItem>
                <ListItem
                    exact
                    activeClassName={styles.selected}
                    button
                    component={ForwardNavLink}
                    to={"/dashboard/security" }
                >
                    <ListItemText primary="Security" />
                </ListItem>
            </List>
        </div>
    )
}
export default Sidebar; 
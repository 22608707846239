import React, { useEffect, useState, Fragment } from "react";
import { 
    Button,
    IconButton,
    TextField,
    MenuItem,
    Chip,
    Table,
    TableBody,
    TableCell, 
    TableContainer,
    TableHead,
    TableRow,
    Paper,
    InputAdornment,
    LinearProgress
} from '@material-ui/core';
import { useSelector, useDispatch } from 'react-redux';
import { useForm, useFieldArray, Controller  } from "react-hook-form";
//import classNames from "classnames";
//import styles from './RoomLayout.module.scss';
import { useSnackbar } from 'notistack';
import AutoComplete from './../AutoComplete';
import Selector from './../Selector';
import options from './../../Wizard/options.json'
import { DeleteForever } from 'mdi-material-ui'
import { create, find, deleteLayout } from './../../helpers/layout';
import { parseBackendErrors } from '../../helpers/parseBackendErrors';

const RoomLayout = ({property_id}) => {

    // state
    const [loading, setLoading] = useState(true);
    const [submitting, setSubmitting] = useState(false);
    const [deleting, setDeleting] = useState(false);

    // redux
    const { app } = useSelector(state => state);
    const dispatch = useDispatch();

    // hooks
    const { enqueueSnackbar } = useSnackbar();
    const {  control, handleSubmit, setValue, errors, register } = useForm({
        mode: "onChange",
        reValidateMode: "onChange"
    });
    const { fields, append, remove } = useFieldArray({
        control,
        name: "layout"
    });

    // lifecycles
    useEffect(() => {
        handleGetPropertyById(property_id);
    }, []);

    // methods
    const handleGetPropertyById = async(id) => {
        const [error, response] = await find(id);
        setLoading(false);
        if(error) return;
        for(let i in response.data) {
            let description = response.data[i]["description"].map((item) => {
                let index = options.description.findIndex(index => index.field === item.field)
                return options.description[index];
            });
            append({
                level: response.data[i]["level"],
                room: response.data[i]["room"],
                description: description,
                width: response.data[i]["width"],
                length: response.data[i]["length_"],
                _id: response.data[i]["_id"]
            });
        }
    }

    // methods
    const addRoom = () => {
        if(fields.length >= 15) {
            enqueueSnackbar("You can't add more than 15 rooms", { 
                variant: 'warning',
                anchorOrigin: {
                    vertical: 'top',
                    horizontal: 'center',
                }
            });
            return;
        }
        append({
            level: '',
            room: '',
            description: [],
            width: '',
            length: '',
            _id: ''
        });
    }

    const submitLayout = async (form) => {
        setSubmitting(true);
        const [error,] = await create(form,property_id,app.token);
        setSubmitting(false);
        if(error) {
            const parsed_errors = parseBackendErrors(error.response);
            enqueueSnackbar(parsed_errors, { 
                variant: 'error',
                anchorOrigin: {
                    vertical: 'top',
                    horizontal: 'center',
                }
            });
            return;
        }
        enqueueSnackbar('This layout has been updated!', { 
            variant: 'success',
            anchorOrigin: {
                vertical: 'top',
                horizontal: 'center',
            }
        });
    }

    const handleDeleteLayout = async (index,id) => {
        if(!id) {
            remove(index);
            return;
        }
        setDeleting(true);
        const [error,] = await deleteLayout(property_id,id,app.token);
        setDeleting(false);
        if(error) {
            const parsed_errors = parseBackendErrors(error.response);
            enqueueSnackbar(parsed_errors, { 
                variant: 'error',
                anchorOrigin: {
                    vertical: 'top',
                    horizontal: 'center',
                }
            });
            return;
        }
        remove(index);
        enqueueSnackbar('This layout has been deleted!', { 
            variant: 'success',
            anchorOrigin: {
                vertical: 'top',
                horizontal: 'center',
            }
        });
    }
    
    if(loading) {
        return <LinearProgress/>
    }
    
    return (
        <>
            <form onSubmit={handleSubmit(submitLayout)} className="w-100">
                <Button 
                    variant="contained"
                    onClick={addRoom}
                    color="primary">
                    Add Room
                </Button>
                <TableContainer component={Paper} className="mt-3">
                    <Table className="w-100" aria-label="Room Layout">
                        <TableHead>
                            <TableRow>
                                <TableCell style={{ width: '30px' }}>#</TableCell>
                                <TableCell align="left" style={{ width: '20%' }}>Level</TableCell>
                                <TableCell align="left" style={{ width: '20%' }}>Room</TableCell>
                                <TableCell align="left" style={{ width: '25%' }}>Length / Width</TableCell>
                                <TableCell align="left" style={{ width: '35%' }}>Description</TableCell>
                                <TableCell align="left"/>
                            </TableRow> 
                        </TableHead>
                        <TableBody>
                            {fields.map((item,index) => {
                                return (
                                    <TableRow key={index} style={{verticalAlign: "top"}}>
                                        <TableCell>
                                            <div style={{marginTop: 10}}>{index + 1}</div>
                                        </TableCell>
                                        <TableCell align="left">
                                            <Selector
                                                name={`layout[${index}].level`}
                                                leanName="level"
                                                label="Level"
                                                control={control}
                                                defaultValue={item.level}
                                                variant="outlined"
                                                size="small"
                                                fullWidth={true}
                                                errors={errors['layout']?.[index]}
                                                rules={{ 
                                                    required: "This field is required."
                                                }}
                                            >
                                                <MenuItem value="">Please select</MenuItem>
                                                {
                                                    options.level.map((option,i) => {
                                                        return (
                                                            <MenuItem value={option.field} key={i}>{option.value}</MenuItem>
                                                        )
                                                    })
                                                }
                                            </Selector>
                                        </TableCell>
                                        <TableCell align="left">
                                            <Selector
                                                name={`layout[${index}].room`}
                                                leanName="room"
                                                label="Room"
                                                control={control}
                                                defaultValue={item.room}
                                                variant="outlined"
                                                size="small"
                                                fullWidth={true}
                                                errors={errors['layout']?.[index]}
                                                rules={{ 
                                                    required: "This field is required."
                                                }}
                                            >
                                                <MenuItem value="">Please select</MenuItem>
                                                {
                                                    options.room.map((option,i) => {
                                                        return (
                                                            <MenuItem value={option.field} key={i}>{option.value}</MenuItem>
                                                        )
                                                    })
                                                }
                                            </Selector>
                                        </TableCell>
                                        <TableCell align="left">
                                            <div className="row">
                                                <div className="col-6 pe-0">
                                                    <Controller
                                                        as={
                                                            <TextField 
                                                                type="number"
                                                                label="Length"
                                                                fullWidth={true}
                                                                size="small"
                                                                variant="outlined"
                                                                inputProps={{maxLength: 10}}
                                                                InputProps={{
                                                                    endAdornment: <InputAdornment position="end">ft</InputAdornment>,
                                                                }}
                                                            />
                                                        }
                                                        name={`layout[${index}]['length']`}
                                                        control={control}
                                                        defaultValue={item.length}
                                                    />
                                                </div>
                                                <div className="col-6 ps-0">
                                                    <Controller
                                                        as={
                                                            <TextField 
                                                                type="number"
                                                                label="Width"
                                                                fullWidth={true}
                                                                className="ms-1"
                                                                size="small"
                                                                variant="outlined"
                                                                inputProps={{maxLength: 10}}
                                                                InputProps={{
                                                                    endAdornment: <InputAdornment position="end">ft</InputAdornment>,
                                                                }}
                                                            />
                                                        }
                                                        name={`layout[${index}]['width']`}
                                                        control={control}
                                                        defaultValue={item.width}
                                                    />
                                                </div>
                                            </div>
                                        </TableCell>
                                        <TableCell align="left">
                                            <AutoComplete
                                                control={control}
                                                name={`layout[${index}]['description']`}
                                                leanName="description"
                                                size="small"
                                                options={options.description}
                                                getOptionLabel={(option) => option.value}
                                                renderOption={(option) => (
                                                    <span>
                                                        {option.value}
                                                    </span>
                                                )}
                                                renderTags={(value, getTagProps) =>
                                                    value.map((option, index) => (
                                                        <Chip 
                                                            color="primary"
                                                            label={option.value}
                                                            size="small"
                                                            {...getTagProps({ index })}
                                                        />
                                                    ))
                                                }
                                                multiple
                                                renderInput={(params) => {
                                                    return (
                                                        <TextField
                                                            {...params}
                                                            fullWidth={true}
                                                            size="small"
                                                            label="Description" 
                                                            variant="outlined"
                                                            className="mb-0"
                                                        />
                                                    )
                                                }}
                                                defaultValue={item.description}
                                                errors={errors['layout']?.[index]}
                                                rules={{ 
                                                    required: "This field is required."
                                                }}
                                            />   
                                        </TableCell>
                                        <TableCell align="right">
                                            <input
                                                ref={register()}
                                                type="hidden"
                                                name={`layout[${index}]._id`}
                                                defaultValue={item._id}
                                            />
                                            <IconButton disabled={deleting} size="small" color="secondary" onClick={() => handleDeleteLayout(index,item._id)} style={{marginTop: 5}}>
                                                <DeleteForever/>
                                            </IconButton>
                                        </TableCell>
                                    </TableRow>
                                )
                            })}
                        </TableBody>
                    </Table>
                </TableContainer>
                <Button 
                    variant="contained"
                    type="submit"
                    color="primary"
                    className="mt-3"
                    disabled={submitting || fields.length === 0}>
                    Save Layout
                </Button>
            </form>
        </>
    )
}

export default RoomLayout;
import React, { useEffect, useState, forwardRef } from "react";
import { 
    IconButton,
    Chip,
    Button,
    Table,
    TableBody,
    TableCell, 
    TableContainer,
    TableHead,
    TableRow,
    Paper,
    Typography,
    LinearProgress
} from '@material-ui/core';
import { useSelector, useDispatch } from 'react-redux';
import { useSnackbar } from 'notistack';
import { find, deleteProperty } from './../../helpers/property';
import { TrashCanOutline, SquareEditOutline } from 'mdi-material-ui'
import { NavLink } from 'react-router-dom';
import { parseBackendErrors } from '../../helpers/parseBackendErrors';

const ForwardNavLink = forwardRef((props, ref) => (
    <NavLink {...props} innerRef={ref} />
))

const Properties = () => {

    // state
    const [loading, setLoading] = useState(true);
    const [properties, setProperties] = useState([]);
    const [deleting, setDeleting] = useState(false);

    // redux
    const { wizard, app } = useSelector(state => state);
    const dispatch = useDispatch();

    // hooks
    const { enqueueSnackbar } = useSnackbar();

    // lifecycles
    useEffect(() => {
        async function handleFindProperties() {
            const query = `?user=${app.user._id}`;
            const [error, response] = await find(query);
            setLoading(false);
            if(error) {
                return;
            }
            setProperties(response.data);
        }
        handleFindProperties();
    },[]);

    const handleDeleteProperty = async(id) => {
        if( window.confirm("Are you sure you want to delete this property. This action is not reversible.") ) {
            setDeleting(true);
            const [error,] = await deleteProperty(id,app.token);
            setDeleting(false);
            if(error) {   
                const parsed_errors = parseBackendErrors(error.response);
                enqueueSnackbar(parsed_errors, { 
                    variant: 'error',
                    anchorOrigin: {
                        vertical: 'top',
                        horizontal: 'center',
                    }
                });
                return;
            }
            enqueueSnackbar('Your property has been deleted!', { 
                variant: 'success',
                anchorOrigin: {
                    vertical: 'top',
                    horizontal: 'center',
                }
            });
            setDeleting(false);
            setProperties(properties.filter(item => item._id !== id));
        }
    }

    return (
        <>
            <Typography variant="h6" className="mb-3">My Properties</Typography>
            {
                loading ? (
                    <LinearProgress/>
                ) : (
                    <>
                        {
                            properties.length === 0 ? (
                                <Typography variant="caption" className="text-center d-block">You don't have any properties yet.</Typography>
                            ) : (
                                <TableContainer component={Paper}>
                                    <Table className="w-100" aria-label="List of Properties">
                                        <TableHead>
                                            <TableRow>
                                                <TableCell style={{ width: '30px' }}>#</TableCell>
                                                <TableCell align="left" style={{ width: '40%' }}>Address</TableCell>
                                                <TableCell align="left" style={{ width: '25%' }}>Images</TableCell>
                                                <TableCell align="left" style={{ width: '25%' }}>Layout</TableCell>
                                                <TableCell align="left" style={{ width: '10%' }}>Status</TableCell>
                                                <TableCell style={{ width: '30px' }} align="left"></TableCell>
                                            </TableRow> 
                                        </TableHead>
                                        <TableBody>
                                            {properties?.map((item,index) => {
                                                return (
                                                    <TableRow key={index}>
                                                        <TableCell>
                                                            {index + 1}
                                                        </TableCell>
                                                        <TableCell align="left">
                                                            <Typography variant="body2">{item?.unit_number} {item?.street_number} {item?.street_name}</Typography>
                                                        </TableCell>
                                                        <TableCell align="left">
                                                            <Chip clickable component={ForwardNavLink} to={"/dashboard/listings/" + item?._id + "/media" } color="primary" size="small" label="Upload"/>
                                                        </TableCell>
                                                        <TableCell align="left">
                                                            <Chip clickable component={ForwardNavLink} to={"/dashboard/listings/" + item?._id + "/layout" } color="primary" size="small" label="Manage"/>
                                                        </TableCell>
                                                        <TableCell align="left">
                                                            <Typography variant="body2">{item.status}</Typography>
                                                        </TableCell>
                                                        <TableCell align="right">
                                                            <div className="w-100 d-flex">
                                                                <IconButton size="small" color="secondary" disabled={deleting} onClick={() => handleDeleteProperty(item._id)}>
                                                                    <TrashCanOutline/>
                                                                </IconButton>
                                                                <IconButton size="small" component={ForwardNavLink} to={"/dashboard/listings/" + item?._id + "/edit" }>
                                                                    <SquareEditOutline/>
                                                                </IconButton>
                                                            </div>
                                                        </TableCell>
                                                    </TableRow>
                                                )
                                            })}
                                        </TableBody>
                                    </Table>
                                </TableContainer>
                            )
                        }
                    </>
                )
            }
        </>
    )
}
export default Properties; 
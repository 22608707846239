import React, { useEffect } from "react";
import { 
    Button,
    TextField,
    Typography,
    LinearProgress
} from '@material-ui/core';
import { useForm, Controller } from "react-hook-form";
import { useSelector } from 'react-redux';

const Step6 = ({handleBackCallback, handleCompleteWizardCallback, submitting, mode}) => {

    // misc hooks
    const { handleSubmit, control, errors, setValue, getValues  } = useForm();

    // redux
    const { wizard } = useSelector(state => state);

    // methods
    const handleCompleteWizard = async (form) => {
        handleCompleteWizardCallback(form);
    }

    const handleBackStep = async () => {
        const payload = {
            sales_pitch_1: getValues("sales_pitch_1"),
            sales_pitch_2: getValues("sales_pitch_2")
        };
        handleBackCallback(payload);
    }

    useEffect(() => {
        setValue("sales_pitch_1", wizard?.pitch?.sales_pitch_1);
        setValue("sales_pitch_2", wizard?.pitch?.sales_pitch_2);
    },[setValue,wizard?.pitch]);

    return (
        <div className="row">
            <div className="col-12">
                <form onSubmit={handleSubmit(handleCompleteWizard)} className="w-100">
                    <Typography variant="body1" className="mb-1">Tell us the best parts about your house as if it were your sales pitch:</Typography>
                    <Controller
                        as={
                            <TextField 
                                label="Entry 1"
                                autoComplete="new-password"
                                fullWidth={true}
                                size="small"
                                variant="outlined"
                                error={errors.sales_pitch_1 && true}
                                helperText={ "Optional. Maximum 2000 characters." }
                                multiline
                                rows={4}
                                className="mb-3"
                                inputProps={
                                    {maxLength: 2000}
                                }
                            />
                        }
                        name="sales_pitch_1"
                        control={control}
                        defaultValue={""}
                        rules={{ 
                            //required: "This field is required."
                        }}
                    />
                    <Typography variant="body1" className="mb-1">Tell us about what is included in your house, ie: appliances, blinds, lighting, etc:</Typography>
                    <Controller
                        as={
                            <TextField 
                                label="Entry 2"
                                autoComplete="new-password"
                                fullWidth={true}
                                size="small"
                                variant="outlined"
                                error={errors.sales_pitch_2 && true}
                                helperText={ "Optional. Maximum 2000 characters." }
                                multiline
                                rows={4}
                                className="mb-3"
                                inputProps={
                                    {maxLength: 2000}
                                }
                            />
                        }
                        name="sales_pitch_2"
                        control={control}
                        defaultValue={""}
                        rules={{ 
                            //required: "This field is required."
                        }}
                    />
                    <div className="d-flex align-items-center">
                        {
                            mode === "wizard" ? (
                                <>
                                    <Button 
                                        disabled={submitting}
                                        variant="outlined"
                                        color="primary"
                                        disableElevation
                                        size="small"
                                        onClick={() => handleBackStep()}
                                        >
                                        Previous
                                    </Button>
                                    <Button 
                                        disabled={submitting}
                                        className="mx-3"
                                        variant="outlined"
                                        color="primary"
                                        disableElevation
                                        size="small"
                                        type="submit"
                                        >
                                        Complete
                                    </Button>
                                </>
                            ) : (
                                <Button 
                                    disabled={submitting}
                                    variant="outlined"
                                    color="primary"
                                    disableElevation
                                    className="me-3"
                                    size="small"
                                    type="submit">
                                    Save Changes
                                </Button>
                            )
                        }
                        { submitting && <LinearProgress style={{width: '30px'}}/> }
                    </div>
                </form>
            </div>
        </div>
    )
}
export default Step6;
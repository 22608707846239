import React, { useEffect } from "react";
import { 
    Button,
    LinearProgress,
    TextField,
    MenuItem
} from '@material-ui/core';
import { useForm, Controller } from "react-hook-form";
import { useSelector, useDispatch } from 'react-redux';
import Selector from './../components/Selector';
import options from './options.json'

const Step4 = ({handleNextCallback, handleBackCallback, submitting, mode}) => {

    // misc hooks
    const { handleSubmit, control, errors, setValue, getValues  } = useForm();

    // redux
    const { wizard } = useSelector(state => state);

    // methods
    const handleNextStep = async (form) => {
        handleNextCallback(form);
    }

    const handleBackStep = async () => {
        const payload = {
            rooms: getValues("rooms"),
            full_washroom: getValues("full_washroom"),
            half_washroom: getValues("half_washroom"),
            bedrooms: getValues("bedrooms"),
            kitchens: getValues("kitchens"),
            family_room: getValues("family_room"),
            basement: getValues("basement"),
            fireplace: getValues("fireplace"),
            heat_source: getValues("heat_source"),
            heat_type: getValues("heat_type"),
            air_conditioning: getValues("air_conditioning")
        };
        handleBackCallback(payload);
    }

    useEffect(() => {
        setValue("rooms", (wizard?.interior?.rooms !== null && wizard?.interior?.rooms !== undefined) ? wizard?.interior?.rooms : '');
        setValue("full_washroom", (wizard?.exterior?.full_washroom !== null && wizard?.interior?.full_washroom !== undefined) ? wizard?.interior?.full_washroom : '');
        setValue("half_washroom", (wizard?.interior?.half_washroom !== null && wizard?.interior?.half_washroom !== undefined) ? wizard?.interior?.half_washroom : '');
        setValue("bedrooms", (wizard?.interior?.bedrooms !== null && wizard?.interior?.bedrooms !== undefined) ? wizard?.interior?.bedrooms : '');
        setValue("kitchens", (wizard?.interior?.kitchens !== null && wizard?.interior?.kitchens !== undefined) ? wizard?.interior?.kitchens : '');
        setValue("family_room", (wizard?.interior?.family_room !== null && wizard?.interior?.family_room !== undefined) ? wizard?.interior?.family_room : '');
        setValue("basement", wizard?.interior?.basement ? wizard?.interior?.basement : '');
        setValue("fireplace", (wizard?.interior?.fireplace !== null && wizard?.interior?.fireplace !== undefined) ? wizard?.interior?.fireplace : '');
        setValue("heat_source", wizard?.interior?.heat_source ? wizard?.interior?.heat_source : '');
        setValue("heat_type", wizard?.interior?.heat_type ? wizard?.interior?.heat_type : '');
        setValue("air_conditioning", wizard?.interior?.air_conditioning ? wizard?.interior?.air_conditioning : '');
    },[setValue,wizard?.interior]);

    return (
        <div className="row">
            <div className="col-12">
                <form onSubmit={handleSubmit(handleNextStep)} className="w-100">
                    <Controller
                        as={
                            <TextField 
                                label="Total Amount of Rooms"
                                type="number"
                                autoComplete="new-password"
                                fullWidth={true}
                                size="small"
                                variant="outlined"
                                error={errors.rooms && true}
                                helperText={ errors?.rooms?.message }
                                className="mb-3"
                                InputProps={{
                                    inputProps: { 
                                        max: 100, min: 0 
                                    }
                                }}
                            />
                        }
                        name="rooms"
                        control={control}
                        defaultValue={""}
                        rules={{ 
                            required: "This field is required."
                        }}
                    />
                    <Controller
                        as={
                            <TextField 
                                label="Amount of Full Washrooms"
                                type="number"
                                autoComplete="new-password"
                                fullWidth={true}
                                size="small"
                                variant="outlined"
                                error={errors.full_washroom && true}
                                helperText={ errors?.rooms?.full_washroom }
                                className="mb-3"
                                InputProps={{
                                    inputProps: { 
                                        max: 100, min: 0
                                    }
                                }}
                            />
                        }
                        name="full_washroom"
                        control={control}
                        defaultValue={""}
                        rules={{ 
                            required: "This field is required."
                        }}
                    />
                    <Controller
                        as={
                            <TextField 
                                label="Amount of Half Washrooms"
                                type="number"
                                autoComplete="new-password"
                                fullWidth={true}
                                size="small"
                                variant="outlined"
                                error={errors.half_washroom && true}
                                helperText={ errors?.rooms?.half_washroom }
                                className="mb-3"
                                InputProps={{
                                    inputProps: { 
                                        max: 100, min: 0 
                                    }
                                }}
                            />
                        }
                        name="half_washroom"
                        control={control}
                        defaultValue={""}
                        rules={{ 
                            required: "This field is required."
                        }}
                    />
                    <Controller
                        as={
                            <TextField 
                                label="Amount of Bedrooms"
                                type="number"
                                autoComplete="new-password"
                                fullWidth={true}
                                size="small"
                                variant="outlined"
                                error={errors.bedrooms && true}
                                helperText={ errors?.rooms?.bedrooms }
                                className="mb-3"
                                InputProps={{
                                    inputProps: { 
                                        max: 100, min: 0 
                                    }
                                }}
                            />
                        }
                        name="bedrooms"
                        control={control}
                        defaultValue={""}
                        rules={{ 
                            required: "This field is required."
                        }}
                    />
                    <Controller
                        as={
                            <TextField 
                                label="Amount of Kitchens"
                                type="number"
                                autoComplete="new-password"
                                fullWidth={true}
                                size="small"
                                variant="outlined"
                                error={errors.kitchens && true}
                                helperText={ errors?.rooms?.kitchens }
                                className="mb-3"
                                InputProps={{
                                    inputProps: { 
                                        max: 100, min: 0 
                                    }
                                }}
                            />
                        }
                        name="kitchens"
                        control={control}
                        defaultValue={""}
                        rules={{ 
                            required: "This field is required."
                        }}
                    />
                    <Selector
                        name="family_room"
                        className={"mb-3"}
                        label="Do you have a Family Room"
                        control={control}
                        defaultValue={""}
                        variant="outlined"
                        size="small"
                        fullWidth={true}
                        rules={{ 
                            required: "This field is required." 
                        }}
                        errors={errors}
                    >
                        <MenuItem value="">Please select</MenuItem>
                        <MenuItem value="yes">Yes</MenuItem>
                        <MenuItem value="no">No</MenuItem>
                    </Selector>
                    {
                        wizard.type !== "condo" && (
                            <>
                                <Selector
                                    name="basement"
                                    className={"mb-3"}
                                    label="Basement"
                                    control={control}
                                    defaultValue={""}
                                    variant="outlined"
                                    size="small"
                                    fullWidth={true}
                                    rules={{ 
                                        required: "This field is required." 
                                    }}
                                    errors={errors}
                                >
                                    <MenuItem value="">Please select</MenuItem>
                                    {
                                        options.interior.basement.map((option,i) => {
                                            return (
                                                <MenuItem value={option.field} key={i}>{option.value}</MenuItem>
                                            )
                                        })
                                    }
                                </Selector>
                            </>
                        )
                    }
                    <Selector
                        name="fireplace"
                        className={"mb-3"}
                        label="Do you have a Fireplace"
                        control={control}
                        defaultValue={""}
                        variant="outlined"
                        size="small"
                        fullWidth={true}
                        rules={{ 
                            required: "This field is required." 
                        }}
                        errors={errors}
                    >
                        <MenuItem value="">Please select</MenuItem>
                        <MenuItem value="yes">Yes</MenuItem>
                        <MenuItem value="no">No</MenuItem>
                    </Selector>
                    <Selector
                        name="heat_source"
                        className={"mb-3"}
                        label="Heat Source"
                        control={control}
                        defaultValue={""}
                        variant="outlined"
                        size="small"
                        fullWidth={true}
                        rules={{ 
                            required: "This field is required." 
                        }}
                        errors={errors}
                    >
                        <MenuItem value="">Please select</MenuItem>
                        {
                            options.interior.heat_source.map((option,i) => {
                                return (
                                    <MenuItem value={option.field} key={i}>{option.value}</MenuItem>
                                )
                            })
                        }
                    </Selector>
                    <Selector
                        name="heat_type"
                        className={"mb-3"}
                        label="Heat Type"
                        control={control}
                        defaultValue={""}
                        variant="outlined"
                        size="small"
                        fullWidth={true}
                        rules={{ 
                            required: "This field is required." 
                        }}
                        errors={errors}
                    >
                        <MenuItem value="">Please select</MenuItem>
                        {
                            options.interior.heat_type.map((option,i) => {
                                return (
                                    <MenuItem value={option.field} key={i}>{option.value}</MenuItem>
                                )
                            })
                        }
                    </Selector>
                    <Selector
                        name="air_conditioning"
                        className={"mb-3"}
                        label="Air Conditioning"
                        control={control}
                        defaultValue={""}
                        variant="outlined"
                        size="small"
                        fullWidth={true}
                        rules={{ 
                            required: "This field is required." 
                        }}
                        errors={errors}
                    >
                        <MenuItem value="">Please select</MenuItem>
                        {
                            options.interior.air_conditioning.map((option,i) => {
                                return (
                                    <MenuItem value={option.field} key={i}>{option.value}</MenuItem>
                                )
                            })
                        }
                    </Selector>
                    <div className="d-flex align-items-center">
                        {
                            mode === "wizard" ? (
                                <>
                                    <Button 
                                        disabled={submitting}
                                        variant="outlined"
                                        color="primary"
                                        disableElevation
                                        size="small"
                                        onClick={() => handleBackStep()}
                                        >
                                        Previous
                                    </Button>
                                    <Button 
                                        disabled={submitting}
                                        className="mx-3"
                                        variant="outlined"
                                        color="primary"
                                        disableElevation
                                        size="small"
                                        type="submit"
                                        >
                                        Save & Continue
                                    </Button>
                                </>
                            ) : (
                                <Button 
                                    disabled={submitting}
                                    variant="outlined"
                                    color="primary"
                                    disableElevation
                                    className="me-3"
                                    size="small"
                                    type="submit">
                                    Save Changes
                                </Button>
                            )
                        }
                        { submitting && <LinearProgress style={{width: '30px'}}/> }
                    </div>
                </form>
            </div>
        </div>
    )
}
export default Step4; 
import React, { useEffect } from "react";
import { 
    Button,
    TextField,
    InputAdornment,
    MenuItem,
    LinearProgress
} from '@material-ui/core';
import { useForm, Controller } from "react-hook-form";
import Selector from './../components/Selector';
import { useSelector } from 'react-redux';
import options from './options.json';

const Step1 = ({handleNextCallback, handleBackCallback, submitting, mode}) => {

    // misc hooks
    const { handleSubmit, control, errors, setValue, getValues  } = useForm();

    // redux
    const { wizard } = useSelector(state => state);

    // methods
    const handleNextStep = async (form) => {
        handleNextCallback(form);
    }

    const handleBackStep = async () => {
        const payload = {
            fronting: getValues("fronting"),
            size_frontage: getValues("size_frontage"),
            size_depth: getValues("size_depth")
        };
        handleBackCallback(payload);
    }

    useEffect(() => {
        setValue("fronting", wizard?.lot?.fronting ? wizard?.lot?.fronting : '');
        setValue("size_frontage", wizard?.lot?.size_frontage !== '' ? wizard?.lot?.size_frontage : '');
        setValue("size_depth", wizard?.lot?.size_depth !== '' ? wizard?.lot?.size_depth : '');
    },[setValue,wizard?.lot]);

    return (
        <div className="row">
            <div className="col-12">
                <form onSubmit={handleSubmit(handleNextStep)} className="align-self-center w-100">
                    <Selector
                        name="fronting"
                        className={"mb-3"}
                        label="Fronting"
                        control={control}
                        defaultValue={""}
                        variant="outlined"
                        size="small"
                        fullWidth={true}
                        rules={{ 
                            required: "This field is required." 
                        }}
                        errors={errors}
                    >
                        <MenuItem value="">Please select</MenuItem>
                        {
                            options.fonting.map((option,i) => {
                                return (
                                    <MenuItem value={option.field} key={i}>{option.value}</MenuItem>
                                )
                            })
                        }
                    </Selector>
                    {
                        wizard.type !== "condo" && (
                            <>
                                <Controller
                                    as={
                                        <TextField 
                                            label="Lot Size Frontage"
                                            type="number"
                                            autoComplete="new-password"
                                            fullWidth={true}
                                            size="small"
                                            variant="outlined"
                                            error={errors.size_frontage && true}
                                            helperText={ errors?.size_frontage?.message }
                                            className="mb-3"
                                            InputProps={{
                                                endAdornment: <InputAdornment position="end">Sq. Feet</InputAdornment>,
                                                inputProps: { 
                                                    max: 9999999, min: 0 
                                                }
                                            }}
                                        />
                                    }
                                    name="size_frontage"
                                    control={control}
                                    defaultValue={""}
                                    rules={{ 
                                        required: "This field is required! Put 0 if you don't know."
                                    }}
                                />
                                <Controller
                                    as={
                                        <TextField 
                                            label="Lot Size Depth"
                                            type="number"
                                            autoComplete="new-password"
                                            fullWidth={true}
                                            size="small"
                                            variant="outlined"
                                            error={errors.size_depth && true}
                                            helperText={ errors?.size_depth?.message }
                                            className="mb-3"
                                            InputProps={{
                                                endAdornment: <InputAdornment position="end">Sq. Feet</InputAdornment>,
                                                inputProps: { 
                                                    max: 9999999, min: 0 
                                                }
                                            }}
                                        />
                                    }
                                    name="size_depth"
                                    control={control}
                                    defaultValue={""}
                                    rules={{ 
                                        required: "This field is required! Put 0 if you don't know."
                                    }}
                                />
                            </>
                        )
                    }
                    <div className="d-flex align-items-center">
                        {
                            mode === "wizard" ? (
                                <>
                                    <Button 
                                        disabled={submitting}
                                        variant="outlined"
                                        color="primary"
                                        disableElevation
                                        size="small"
                                        onClick={() => handleBackStep()}
                                        >
                                        Previous
                                    </Button>
                                    <Button 
                                        disabled={submitting}
                                        className="mx-3"
                                        variant="outlined"
                                        color="primary"
                                        disableElevation
                                        size="small"
                                        type="submit"
                                        >
                                        Save & Continue
                                    </Button>
                                </>
                            ) : (
                                <Button 
                                    disabled={submitting}
                                    variant="outlined"
                                    color="primary"
                                    disableElevation
                                    className="me-3"
                                    size="small"
                                    type="submit">
                                    Save Changes
                                </Button>
                            )
                        }
                        { submitting && <LinearProgress style={{width: '30px'}}/> }
                    </div>
                </form>
            </div>
        </div>
    )
}
export default Step1; 
import axios from "axios";
import config from "../config";

export const create = async (data,token) => {
  try {
    let response = await axios({
      method: "post",
      url: `${config.SERVER_URL}/properties/`,
      headers: {
        'Authorization': 'Bearer ' + token
      },
      data: data
    });
    return [null, response];
  } catch (error) {
    return [error];
  }
};

export const update = async (data,id,token) => {
  try {
    const payload = {...data};
    let response = await axios({
      method: "put",
      url: `${config.SERVER_URL}/properties/${id}`,
      headers: {
        'Authorization': 'Bearer ' + token
      },
      data: cleanupPayload(payload)
    });
    return [null, response];
  } catch (error) {
    return [error];
  }
};

export const find = async (query) => {
  try {
    let response = await axios({
      method: "get",
      url: `${config.SERVER_URL}/properties/${query}`
    });
    return [null, response];
  } catch (error) {
    return [error];
  }
};


/**
 * Get property data by its ID
 */

export const getPropertyById = async (id,token) => {
  try {
    let response = await axios({
      method: "get",
      url: `${config.SERVER_URL}/properties/${id}`
    });
    return [null, response];
  } catch (error) {
    return [error];
  }
};

export const deletePhoto = async (property_id,photo_id,token) => {
  try {
    let response = await axios({
      method: "delete",
      url: `${config.SERVER_URL}/properties/${property_id}/photo/${photo_id}`,
      headers: {
        'Authorization': 'Bearer ' + token
      }
    });
    return [null, response];
  } catch (error) {
    return [error];
  }
};

export const updatePhoto = async (data,property_id,photo_id,token) => {
  try {
    let response = await axios({
      method: "put",
      url: `${config.SERVER_URL}/properties/${property_id}/photo/${photo_id}`,
      headers: {
        'Authorization': 'Bearer ' + token
      },
      data: data
    });
    return [null, response];
  } catch (error) {
    return [error];
  }
};

export const deleteProperty = async (property_id,token) => {
  try {
    let response = await axios({
      method: "delete",
      url: `${config.SERVER_URL}/properties/${property_id}/`,
      headers: {
        'Authorization': 'Bearer ' + token
      }
    });
    return [null, response];
  } catch (error) {
    return [error];
  }
};

/**
 * Remove empty properties from payload (eg. something: "")
 */

const cleanupPayload = (obj) => {
  for (var propName in obj) {
    if (obj[propName] === "") {
      delete obj[propName];
    }
  }
  return obj
}

import React, { useEffect, useState } from "react";
import { Route } from "react-router-dom";
import { 
    Typography,
    Stepper,
    Step,
    StepLabel,
    StepContent
} from '@material-ui/core';
import { Step0, Step1, Step2, Step3, Step4, Step5, Step6 } from './index';
import { useSelector, useDispatch } from 'react-redux';
import SavedProperty from './components/SavedProperty';
import { find, create, update } from './../helpers/property';
import { Restore, SetLegalName, SetAddress, SetId, SetState, SetType, ResetWizard } from './../store/actions/wizard.js';
import options from './options.json';
import { parseBackendErrors } from '../helpers/parseBackendErrors';
import { useSnackbar } from 'notistack';
//import ConfirmationDialog from './components/ConfirmationDialog';
//import NavigationPrompt from "react-router-navigation-prompt";

const Wizard = (props) => {

    // state
    const [activeStep, setActiveStep] = useState(0);
    const [submitting, setSubmitting] = useState(false);

    // redux
    const { wizard, app } = useSelector(state => state);
    const dispatch = useDispatch();

    // hooks
    const { enqueueSnackbar } = useSnackbar();
    /**
     * Check if a user reloaded the app
     * In such case the redux store is going to be empty
     * Attempt to prefill the data from the backend & reset the steps
     */

    useEffect(() => {
        window.onpopstate  = (e) => {
            let step = parseInt(window.location.pathname.split("/")[2]);
            setActiveStep(step);
        }
    },[]);

     useEffect(() => {
        async function initWizard() {
            if(wizard.state === 'NOT_STARTED' && props?.location?.state?.id) {
                // app was reloaded, restore the progress
                const id = props.location?.state?.id;
                const step = props.location?.state?.step;
                const query = `?_id=${id}`;
                
                const [error, response] = await find(query);
                if(error || response.data.length === 0) {
                    // die
                    return;
                }
                dispatch(Restore(response.data[0]));
                setActiveStep(step);
            }
        }
        initWizard();
    },[activeStep]);

    // methods
    
    const handleStart = async (form) => {
        setSubmitting(true);
        let address_payload = {
            legal_owner: form.legal_owner,
            unit_number: form.unit_number,
            street_number: form.street_number,
            street_name: form.street_name,
            city: form.city,
            province: form.province,
            postal_code: form.postal_code,
            type: form.type
        };
        dispatch(SetLegalName(form.legal_owner))
        dispatch(SetAddress(address_payload));
        dispatch(SetType(form.type));
        if(wizard.state === 'NOT_STARTED') {
            const [error, response] = await create(address_payload,app.token);
            setSubmitting(false);
            if(error) return;
            dispatch(SetId(response.data._id));
            dispatch(SetState(response.data.status));
            props.history.push({
                pathname: '/wizard/1',
                state: { id: response.data._id, step: 1 }
            });
            setActiveStep(activeStep + 1);
        } else if(wizard.state === 'DRAFT') {
            const [error,] = await update(address_payload,wizard._id,app.token);
            setSubmitting(false);
            if(error) return;
            props.history.push({
                pathname: '/wizard/1',
                state: { id: wizard._id, step: 1 }
            });
            setActiveStep(activeStep + 1);
        }
    };

    const handleNext = async (form) => {
        setSubmitting(true);
        const [error,response] = await update(form,wizard._id,app.token);
        if(error) return;
        setSubmitting(false);
        dispatch(Restore(response.data));
        props.history.push({
            pathname: '/wizard/' + (activeStep + 1),
            state: { id: wizard._id, step: (activeStep + 1) }
        });
        setActiveStep(activeStep + 1);
    };
    
    const handleBack = async (form) => {
        setSubmitting(true);
        const [error,response] = await update(form,wizard._id,app.token);
        if(error) return;
        setSubmitting(false);
        dispatch(Restore(response.data));
        props.history.push({
            pathname: '/wizard/' + (activeStep - 1),
            state: { id: wizard._id, step: (activeStep - 1)}
        });
        setActiveStep(activeStep - 1);
    };

    const handleComplete = async (form) => {
        setSubmitting(true);
        form.completed = true;
        const [error,] = await update(form,wizard._id,app.token);
        setSubmitting(false);
        if(error) {
            const parsed_errors = parseBackendErrors(error.response);
            enqueueSnackbar(parsed_errors, { 
                variant: 'error',
                anchorOrigin: {
                    vertical: 'top',
                    horizontal: 'center',
                }
            });
            return;
        };
        dispatch(ResetWizard());
        props.history.push({
            pathname: '/dashboard/listings'
        });
    };

    const handlePropertySelect = async (data) => {
        dispatch(Restore(data));
    }
    
    const getStepContent = (step) => {
        switch (step) {
            case 0:
                return (
                    <Route
                        exact
                        path='/wizard/0'
                        render={(props) => <Step0 mode="wizard" submitting={submitting} {...props} handleStartCallback={(form) => handleStart(form)}/> }
                    />
                )
            case 1:
                return (
                    <Route
                        exact
                        path='/wizard/1'
                        render={(props) => <Step1 mode="wizard" submitting={submitting} {...props}handleBackCallback={(form) => handleBack(form)}  handleNextCallback={(form) => handleNext(form)}/> }
                    />
                )
            case 2:
                return (
                    <Route
                        exact
                        path='/wizard/2'
                        render={(props) => <Step2 mode="wizard" submitting={submitting} {...props} handleNextCallback={(form) => handleNext(form)} handleBackCallback={(form) => handleBack(form)}/> }
                    />
                )
            case 3:
                return (
                    <Route
                        exact
                        path='/wizard/3'
                        render={(props) => <Step3 mode="wizard" submitting={submitting} {...props} handleNextCallback={(form) => handleNext(form)} handleBackCallback={(form) => handleBack(form)}/> }
                    />
                )
            case 4:
                return (
                    <Route
                        exact
                        path='/wizard/4'
                        render={(props) => <Step4 mode="wizard" submitting={submitting} {...props} handleNextCallback={(form) => handleNext(form)} handleBackCallback={(form) => handleBack(form)}/> }
                    />
                )
            case 5:
                return (
                    <Route
                        exact
                        path='/wizard/5'
                        render={(props) => <Step5 mode="wizard" submitting={submitting} {...props} handleNextCallback={(form) => handleNext(form)} handleBackCallback={(form) => handleBack(form)}/> }
                    />
                )
            case 6:
                return (
                    <Route
                        exact
                        path='/wizard/6'
                        render={(props) => <Step6 mode="wizard" submitting={submitting} {...props} handleCompleteWizardCallback={(form) => handleComplete(form)} handleBackCallback={(form) => handleBack(form)}/> }
                    />
                )
            default:
                return 'Unknown step';
        }
    }

    return (
        <>  
            {/*
            <NavigationPrompt when={redux.wizard.state === 'STARTED'}>
                {({ onConfirm, onCancel }) => (
                    <ConfirmationDialog
                        onConfirm={onConfirm}
                        onCancel={onCancel}
                        confirmCallback={saveBeforeLeave}
                    />
                )}
            </NavigationPrompt>
            */}

            <Typography variant="h5">The Wizard 🧙‍♂️ </Typography>
            <Typography variant="body1" className="mb-2">Please follow the steps bellow.</Typography>

            {
                activeStep === 0 && wizard.state === 'NOT_STARTED' && (
                    <div className="row">
                        <div className="col-12">
                            <SavedProperty handlePropertySelectCallback={(data) => handlePropertySelect(data)}/>
                        </div>
                    </div>
                )
            }
            
            <Stepper nonLinear activeStep={activeStep} orientation="vertical" className="px-0">
                {
                    options.steps.map((label, index) => (
                    <Step key={label}>
                        <StepLabel>{label}</StepLabel>
                        <StepContent>
                            {getStepContent(index)}
                        </StepContent>
                    </Step>
                    ))
                }
            </Stepper>       
        </>
    )
}

export default Wizard;
import React from "react";
import { Redirect } from 'react-router-dom';
import styles from './Dashboard.module.scss';
import classNames from "classnames";
import Sidebar from './Sidebar';
import { Route } from "react-router-dom";
import { useSelector } from 'react-redux';
import { Profile, Properties, ManageMedia, EditProperty, ManageLayout, Security } from './Modules';
import LoadingScreen from './../components/LoadingScreen';

const Dashboard = () => {

    // redux
    const { loading, loggedin } = useSelector(state => state.app);

    if(loading) return <LoadingScreen/>;
    if(!loading && !loggedin) return <Redirect to="/"/>

    return (
        <div className={classNames(styles['dashboard'], 'container-fluid h-100')}>
            <div className="row h-100">
                <Sidebar/>
                <div className={classNames(styles['content'], 'col-md-9 col-12 pt-3')}>
                    <Route exact path={'/dashboard/listings'} render={(props) => <Properties {...props} />} />
                    <Route exact path={'/dashboard/listings/:property_id/media'} render={(props) => <ManageMedia {...props} />} />
                    <Route exact path={'/dashboard/listings/:property_id/edit'} render={(props) => <EditProperty {...props} />} />
                    <Route exact path={'/dashboard/listings/:property_id/layout'} render={(props) => <ManageLayout {...props} />} />
                    <Route exact path={'/dashboard/settings'} render={(props) => <Profile {...props} />} />
                    <Route exact path={'/dashboard/security'} render={(props) => <Security {...props} />} />
                </div>
            </div>
        </div>
    )
}
export default Dashboard; 
import React, { useEffect } from "react";
import moment from "moment";
import { 
    Button,
    TextField,
    InputAdornment,
    MenuItem,
    LinearProgress
} from '@material-ui/core';
import { useForm, Controller } from "react-hook-form";
import { useSelector } from 'react-redux';
import Selector from './../components/Selector';

const Step2 = ({handleNextCallback, handleBackCallback, submitting, mode}) => {

    const year = new Date().getFullYear() - 1;

    // misc hooks
    const { handleSubmit, control, errors, setValue, getValues  } = useForm();

    // redux
    const { wizard } = useSelector(state => state);

    // methods
    const handleNextStep = async (form) => {
        handleNextCallback(form);
    }

    const handleBackStep = async () => {
        const payload = {
            list_price: getValues("list_price"),
            annual_taxes: getValues("annual_taxes"),
            tax_year: getValues("tax_year")
        };
        handleBackCallback(payload);
    }

    useEffect(() => {
        setValue("list_price", wizard?.financials?.list_price !== '' ? wizard?.financials?.list_price : '');
        setValue("annual_taxes", wizard?.financials?.annual_taxes !== '' ? wizard?.financials?.annual_taxes : '');
        setValue("tax_year", wizard?.financials?.tax_year ? wizard?.financials?.tax_year : '');
    },[setValue,wizard?.financials]);

    return (
        <div className="row">
            <div className="col-12">
                <form onSubmit={handleSubmit(handleNextStep)} className="align-self-center w-100">
                    <Controller
                        as={
                            <TextField 
                                label="Suggested List Price"
                                type="number"
                                autoComplete="new-password"
                                fullWidth={true}
                                size="small"
                                variant="outlined"
                                error={errors.list_price && true}
                                helperText={errors?.list_price?.message}
                                className="mb-3"
                                InputProps={{
                                    startAdornment: <InputAdornment position="start">C$</InputAdornment>,
                                    inputProps: { 
                                        max: 9999999, min: 0 
                                    }
                                }}
                            />
                        }
                        name="list_price"
                        control={control}
                        defaultValue={""}
                        rules={{ 
                            required: "This field is required! Put 0 if you don't know."
                        }}
                    />
                    <Controller
                        as={
                            <TextField 
                                label="Annual Property Taxes"
                                autoComplete="new-password"
                                type="number"
                                fullWidth={true}
                                size="small"
                                variant="outlined"
                                error={errors.annual_taxes && true}
                                helperText={ errors?.annual_taxes?.message }
                                className="mb-3"
                                InputProps={{
                                    startAdornment: <InputAdornment position="start">C$</InputAdornment>,
                                    inputProps: { 
                                        max: 9999999, min: 0 
                                    }
                                }}
                            />
                        }
                        name="annual_taxes"
                        control={control}
                        defaultValue={""}
                        rules={{ 
                            required: "This field is required! Put 0 if you don't know."
                        }}
                    />
                    <Selector
                        name="tax_year"
                        className={"mb-3"}
                        label="Which Tax Year"
                        control={control}
                        defaultValue={""}
                        variant="outlined"
                        size="small"
                        fullWidth={true}
                        rules={{ 
                            required: "This field is required."
                        }}
                        errors={errors}
                    >
                        <MenuItem value={year.toString()}>{year}</MenuItem>
                    </Selector>
                    <div className="d-flex align-items-center">
                        {
                            mode === "wizard" ? (
                                <>
                                    <Button 
                                        disabled={submitting}
                                        variant="outlined"
                                        color="primary"
                                        disableElevation
                                        size="small"
                                        onClick={() => handleBackStep()}
                                        >
                                        Previous
                                    </Button>
                                    <Button 
                                        disabled={submitting}
                                        className="mx-3"
                                        variant="outlined"
                                        color="primary"
                                        disableElevation
                                        size="small"
                                        type="submit"
                                        >
                                        Save & Continue
                                    </Button>
                                </>
                            ) : (
                                <Button 
                                    disabled={submitting}
                                    variant="outlined"
                                    color="primary"
                                    disableElevation
                                    className="me-3"
                                    size="small"
                                    type="submit">
                                    Save Changes
                                </Button>
                            )
                        }
                        { submitting && <LinearProgress style={{width: '30px'}}/> }
                    </div>
                </form>
            </div>
        </div>
    )
}
export default Step2; 
const initialState = {
    state: 'NOT_STARTED',
    type: '',
    legal_owner: '',
    _id: '',
    address: {
        unit_number: '',
        street_number: '',
        street_name: '',
        city: '',
        province: '',
        postal_code: ''
    },
    lot: {
        fronting: '',
        size_frontage: '',
        size_depth: ''
    },
    financials: {
        list_price: '',
        annual_taxes: '',
        tax_year: ''
    },
    exterior: {
        house_type: '',
        house_style: '',
        exterior: '',
        garage_type: '',
        garage_amount: '',
        locker: '',
        locker_number: '',
        driveway: '',
        driveway_amount: '',
        total_parking_amount: '',
        pool: ''
    },
    technical: {
        water: '',
        sewer: '',
        special_designations: '',
        waterfront: '',
        age: '',
        square_footage: '',
    },
    interior: {
        rooms: '',
        full_washroom: '',
        half_washroom: '',
        bedrooms: '',
        kitchens: '',
        family_room: '',
        basement: '',
        fireplace: '',
        heat_source: '',
        heat_type: '',
        air_conditioning: ''
    },
    pitch: {
        sales_pitch_1: '',
        sales_pitch_2: ''
    }
};

export default function appReducer(state = initialState, action) {

    switch(action.type) {

        case 'SET_STATE': 

            return {
                ...state,
                state: action.payload
            };

        case 'SET_ID': 

            return {
                ...state,
                _id: action.payload
            };

        case 'SET_TYPE': 

            return {
                ...state,
                type: action.payload
            };

        case 'SET_ADDRESS': 

            return {
                ...state,
                address: action.payload
            };

        case 'SET_LEGAL_NAME': 

            return {
                ...state,
                legal_owner: action.payload
            };

        case 'RESTORE': 
            return {
                ...state,
                state: action.payload.status,
                type: action.payload.type,
                legal_owner: action.payload.legal_owner,
                _id: action.payload._id,
                address: {
                    unit_number: action.payload.unit_number,
                    street_number: action.payload.street_number,
                    street_name: action.payload.street_name,
                    city: action.payload.city,
                    province: action.payload.province,
                    postal_code: action.payload.postal_code
                },
                lot: {
                    fronting: action.payload.fronting ? action.payload.fronting : '',
                    size_frontage: action.payload.size_frontage ? action.payload.size_frontage : '',
                    size_depth: action.payload.size_depth ? action.payload.size_depth : ''
                },
                financials: {
                    list_price: action.payload.list_price ? action.payload.list_price : '',
                    annual_taxes: action.payload.annual_taxes ? action.payload.annual_taxes : '',
                    tax_year: action.payload.tax_year ? action.payload.tax_year : ''
                },
                exterior: {
                    house_type: action.payload.house_type ? action.payload.house_type : '',
                    house_style: action.payload.house_style ? action.payload.house_style : '',
                    exterior: action.payload.exterior ? action.payload.exterior : '',
                    garage_type: action.payload.garage_type ? action.payload.garage_type : '',
                    garage_amount: action.payload.garage_amount !== null ? action.payload.garage_amount : '',
                    locker: action.payload.locker === undefined ? '' :  action.payload.locker,
                    locker_number: action.payload.locker_number ? action.payload.locker_number : '',
                    driveway: action.payload.driveway ? action.payload.driveway : '',
                    driveway_amount: action.payload.driveway_amount !== null ? action.payload.driveway_amount : '',
                    total_parking_amount: action.payload.total_parking_amount !== null ? action.payload.total_parking_amount : '',
                    pool: action.payload.pool ? action.payload.pool : ''
                },
                technical: {
                    water: action.payload.water ? action.payload.water : '',
                    sewer: action.payload.sewer ? action.payload.sewer : '',
                    special_designations: action.payload.special_designations ? action.payload.special_designations : '',
                    waterfront: action.payload.waterfront ? action.payload.waterfront : '',
                    age: action.payload.age !== null ? action.payload.age : '',
                    square_footage: action.payload.square_footage !== null ? action.payload.square_footage : '',
                },
                interior: {
                    rooms: action.payload.rooms !== null ? action.payload.rooms : '',
                    full_washroom: action.payload.full_washroom !== null ? action.payload.full_washroom : '',
                    half_washroom: action.payload.half_washroom !== null ? action.payload.half_washroom : '',
                    bedrooms: action.payload.bedrooms !== null ? action.payload.bedrooms : '',
                    kitchens: action.payload.kitchens !== null ? action.payload.kitchens : '',
                    family_room: action.payload.family_room === undefined ? '' :  action.payload.family_room,
                    basement: action.payload.basement ? action.payload.basement : '',
                    fireplace: action.payload.fireplace === undefined ? '' : action.payload.fireplace,
                    heat_source: action.payload.heat_source ? action.payload.heat_source : '',
                    heat_type: action.payload.heat_type ? action.payload.heat_type : '',
                    air_conditioning: action.payload.air_conditioning ? action.payload.air_conditioning : ''
                },
                pitch: {
                    sales_pitch_1: action.payload.sales_pitch_1 ? action.payload.sales_pitch_1 : '',
                    sales_pitch_2: action.payload.sales_pitch_2 ? action.payload.sales_pitch_2 : ''
                }
            }

        case 'RESET_WIZARD': 

            return initialState;

        default:
            return state;
    }

}
import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import './sass/bootstrap.scss';
import './sass/app.scss';
import { MuiThemeProvider, StylesProvider } from '@material-ui/core/styles';
import { SnackbarProvider } from 'notistack';
import CssBaseline from '@material-ui/core/CssBaseline';
import { Provider } from 'react-redux';
import store from './store';
import 'moment-timezone';

// Styling
import theme from './theme';

// Utilities
import Tracker from './GoogleAnalytics.js';

// Wrapper
import Bootstrap from './Bootstrap';

// Pages
import Home from './Home';
import { Login, Signup, Forgot, Reset, AccountConfirmation } from './GuestPages';
import { Dashboard } from './Dashboard';
import { Wizard } from './Wizard';

ReactDOM.render(
  <Provider store={store}>
    <MuiThemeProvider theme={theme}>
      <CssBaseline />
      <StylesProvider injectFirst>
        <SnackbarProvider maxSnack={3}>
          <Router>
            <Switch>
              <Route exact path="/login" component={ Tracker(Login) } />
              <Route exact path="/signup" component={ Tracker(Signup) } />
              <Route exact path="/forgot-password" component={ Tracker(Forgot) } />
              <Route exact path="/reset-password/:code" component={ Tracker(Reset) } />
              <Route exact path="/confirmation" component={ Tracker(AccountConfirmation) } />
              <Bootstrap>
                <Route exact path="/" component={ Tracker(Home) } />
                <Route exact path="/dashboard/:section?/:subsection?/:property_id?/:activity?/:trail?" component={ Tracker(Dashboard) } />
                <Route exact path='/wizard/:step?' component={ Tracker(Wizard) } />
              </Bootstrap>
            </Switch>
          </Router>
        </SnackbarProvider>
      </StylesProvider>
    </MuiThemeProvider>
  </Provider>,
  document.getElementById('root')
);
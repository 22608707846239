import React, { useEffect, useState, useMemo } from "react";
import config from "../../config";
import { 
    Button
} from '@material-ui/core';
import { useSelector, useDispatch } from 'react-redux';
import Uppy from '@uppy/core';
import XHRUpload from '@uppy/xhr-upload';
import Dashboard from '@uppy/dashboard';
import { DashboardModal } from '@uppy/react'
import '@uppy/core/dist/style.css';
import '@uppy/dashboard/dist/style.css';
import { } from '../../store/actions/wizard.js';
import { useSnackbar } from 'notistack';

const Uploader = ({property_id,imageUploadedCallback}) => {

    // state
    const [is_opened, setOpen] = useState(false);

    // redux
    const { app } = useSelector(state => state);
    const dispatch = useDispatch();

    // hooks
    const { enqueueSnackbar } = useSnackbar();

    // methods
    const closeUploader = () => {
        setOpen(false);
    }

    const uppy = useMemo(() => {
        return new Uppy({
            restrictions: {
                maxFileSize: 3000000,
                maxNumberOfFiles: 5,
                minNumberOfFiles: 1,
                allowedFileTypes: ['image/png', 'image/jpeg']
            }
        }).use(Dashboard).use(XHRUpload, { 
            endpoint: `${config.SERVER_URL}/upload`, 
            formData: true, 
            fieldName: 'files',
            headers: {
                'Authorization': `Bearer ${app.token}`
            }
        }).on('complete', result => {
            console.log("Done",result)

            if(result.successful.length > 0) {
                let results = [];
                for(let i in result.successful) {
                    let { response } = result.successful[i];
                    if(response["body"][0]) {
                        results.push(response["body"][0])
                    }
                }
                imageUploadedCallback(results)
                enqueueSnackbar('Your photo has been successfully uploaded!', { 
                    variant: 'success',
                    anchorOrigin: {
                        vertical: 'top',
                        horizontal: 'center',
                    }
                });
                return;
            }
        }).on('file-added', (file) =>{
            uppy.setFileMeta(file.id, {
                ref: 'property',
                refId: property_id,
                field: 'photo',
                /*
                fileInfo: `{
                    "caption": "a caption"
                }`
                */
            });
        }).on('dashboard:modal-closed', () => {
            uppy.reset();
        })
    }, []);

    useEffect(() => {
        return () => uppy.close()
    }, []);

    return (
        <>
            <Button onClick={() => setOpen(true)} variant="contained" color="primary" className="mb-3">
                Upload
            </Button>
            <DashboardModal
                uppy={uppy}
                closeAfterFinish={true}
                closeModalOnClickOutside
                open={is_opened}
                onRequestClose={() =>closeUploader()}
                note="JPEG and PNG only, up to 3MB."
            />
        </>
    )
}

export default Uploader;
import React, { useState, forwardRef } from "react";
import { Link, NavLink } from 'react-router-dom';
import styles from './Header.module.scss';
import classNames from "classnames";
import Logo from '../components/Logo';
import { useSelector, useDispatch  } from 'react-redux';
import Avatar from './../components/Avatar';
import { Logout, toggleSidebar } from './../store/actions/app.js';
import { 
    Divider, 
    IconButton,
    Button,
    Menu,
    MenuItem
} from '@material-ui/core';

const ForwardNavLink = forwardRef((props, ref) => (
    <NavLink {...props} innerRef={ref} />
));

const Header = () => {

    // state
    const [userMenuAnchor, setUserMenuAnchor] = useState(null);

    // redux
    const app = useSelector(state => state.app);
    const dispatch = useDispatch();

    // methods
    const handleUserMenuToggle = (e) => {
        setUserMenuAnchor(e.currentTarget);
    }

    const handleLogout = () => {
        dispatch( Logout() );
    };

    return (
        <div className={classNames(styles['header'], 'container-fluid')}>
            <div className='row align-items-center h-100'>
                <div className="col-4">
                    <Logo/>
                </div>
                <div className="col-8 text-end">
                    {
                        app.loggedin ? (
                            <>
                                <Button 
                                    variant="contained" 
                                    disableElevation
                                    color="primary"
                                    className={styles['new-listing-button']}
                                    component={ForwardNavLink}
                                    size="small"
                                    to={{pathname: '/wizard/0', state: { prevPath: window.location.pathname }}}
                                >New Listing</Button>
                                <IconButton
                                    className="p-0"
                                    onClick={handleUserMenuToggle}
                                >
                                    <Avatar user={app.user} size={33} link={false}/>
                                </IconButton>
                                <Menu
                                    variant={'menu'}
                                    anchorEl={userMenuAnchor}
                                    keepMounted={false}
                                    open={Boolean(userMenuAnchor)}
                                    onClose={() => setUserMenuAnchor(null)}
                                    
                                >
                                    <MenuItem
                                        component={ForwardNavLink}
                                        to={"/dashboard/listings"}
                                        onClick={() => setUserMenuAnchor(null)}
                                        >
                                        My Dashboard
                                    </MenuItem>
                                    <Divider/>
                                    <MenuItem 
                                        onClick={() => {
                                            setUserMenuAnchor(null);
                                            handleLogout();
                                        }}
                                    >
                                        Logout
                                    </MenuItem>
                                </Menu>
                            </>              
                        ) : (
                            <Button 
                                color="primary" 
                                variant="contained" 
                                disableElevation={true}
                                component={Link}
                                to="/login"
                                size="small"
                                >
                                Login
                            </Button>
                        )
                    }
                    
                </div>
            </div>
        </div>
    )
}
export default Header; 
import React, { useEffect } from "react";
import { 
    Button,
    TextField,
    MenuItem,
    LinearProgress
} from '@material-ui/core';
import { useForm, Controller } from "react-hook-form";
import Selector from '../components/Selector';
import { useSelector, useDispatch } from 'react-redux';
import options from './options.json';

const Step0 = ({handleStartCallback, submitting, mode}) => {

    // misc hooks
    const { handleSubmit, control, errors, setValue  } = useForm();

    // redux
    const { wizard } = useSelector(state => state);
    const dispatch = useDispatch();

    // lifecycles
    useEffect(() => {

    },[]);

    // methods
    const handleNextStep = async (form) => {
        handleStartCallback(form)
    }

    useEffect(() => {
        setValue("unit_number", wizard?.address?.unit_number !== '' ? wizard?.address?.unit_number : '');
        setValue("street_number", wizard?.address?.street_number !== '' ? wizard?.address?.street_number : '');
        setValue("street_name", wizard?.address?.street_name !== '' ? wizard?.address?.street_name : '');
        setValue("city", wizard?.address?.city !== '' ? wizard?.address?.city : '');
        setValue("province", wizard?.address?.province !== '' ? wizard?.address?.province : '');
        setValue("postal_code", wizard?.address?.postal_code !== '' ? wizard?.address?.postal_code : '');
        setValue("type", wizard?.type !== '' ? wizard?.type : '');
        setValue("legal_owner", wizard?.legal_owner !== '' ? wizard?.legal_owner : '');
    },[setValue,wizard?.address,wizard?.type,wizard?.legal_owner]);

    return (
        <div className="row">
            <div className="col-12">
                <form onSubmit={handleSubmit(handleNextStep)} className="w-100">
                    <Controller
                        as={
                            <TextField 
                                label="Legal Owner"
                                autoComplete="new-password"
                                fullWidth={true}
                                size="small"
                                variant="outlined"
                                error={errors.legal_owner && true}
                                helperText={
                                    errors?.legal_owner?.message ? errors?.legal_owner?.message : "First and Last name"
                                }
                                inputProps={{maxLength: 50}}
                                className="mb-3"
                            />
                        }
                        name="legal_owner"
                        control={control}
                        defaultValue={""}
                        rules={{ 
                            pattern: {
                                value: /^[a-zA-Z0-9 ]+$/i,
                                message: 'This field must contain only letters and numbers.'
                            },
                            required: "This field is required."
                        }}
                    />
                    <div className="row">
                        <div className="col-6 col-md-3">
                            <Controller
                                as={
                                    <TextField 
                                        label="Unit #"
                                        autoComplete="new-password"
                                        fullWidth={true}
                                        size="small"
                                        variant="outlined"
                                        error={errors.unit_number && true}
                                        helperText={
                                            errors?.unit_number?.message ? errors?.unit_number?.message : "If applicable"
                                        }
                                        inputProps={{maxLength: 10}}
                                        className="mb-3"
                                    />
                                }
                                name="unit_number"
                                control={control}
                                defaultValue={""}
                                rules={{ 
                                    pattern: {
                                        value: /^[a-zA-Z0-9 ]+$/i,
                                        message: 'This field must contain only letters and numbers.'
                                    }
                                }}
                            />
                        </div>
                        <div className="col-6 col-md-3">
                            <Controller
                                as={
                                    <TextField 
                                        label="Street #"
                                        autoComplete="new-password"
                                        fullWidth={true}
                                        size="small"
                                        variant="outlined"
                                        error={errors.street_number && true}
                                        helperText={
                                            errors?.street_number?.message
                                        }
                                        inputProps={{maxLength: 10}}
                                        className="mb-3"
                                    />
                                }
                                name="street_number"
                                control={control}
                                defaultValue={""}
                                rules={{ 
                                    pattern: {
                                        value: /^[a-zA-Z0-9 ]+$/i,
                                        message: 'This field must contain only letters and numbers.'
                                    },
                                    required: "Required."
                                }}
                            />
                        </div>
                        <div className="col-12 col-md-6">
                            <Controller
                                as={
                                    <TextField 
                                        label="Street Name"
                                        autoComplete="new-password"
                                        fullWidth={true}
                                        size="small"
                                        variant="outlined"
                                        error={errors.street_name && true}
                                        helperText={
                                            errors?.street_name?.message
                                        }
                                        inputProps={{maxLength: 100}}
                                        className="mb-3"
                                    />
                                }
                                name="street_name"
                                control={control}
                                defaultValue={""}
                                rules={{ 
                                    pattern: {
                                        value: /^[a-zA-Z0-9 ]+$/i,
                                        message: 'This field must contain only letters and numbers.'
                                    },
                                    required: "This field is required."
                                }}
                            />
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-6 col-12">
                            <Controller
                                as={
                                    <TextField 
                                        label="City"
                                        autoComplete="new-password"
                                        fullWidth={true}
                                        size="small"
                                        variant="outlined"
                                        error={errors.city && true}
                                        helperText={
                                            errors?.city?.message
                                        }
                                        inputProps={{maxLength: 40}}
                                        className="mb-3"
                                    />
                                }
                                name="city"
                                control={control}
                                defaultValue={""}
                                rules={{ 
                                    pattern: {
                                        value: /^[a-zA-Z0-9]+$/i,
                                        message: 'This field must contain only letters and numbers.'
                                    },
                                    required: "This field is required."
                                }}
                            />
                        </div>
                        <div className="col-md-3 col-6">
                            <Selector
                                name="province"
                                className={"mb-3"}
                                label="Province"
                                control={control}
                                defaultValue={""}
                                variant="outlined"
                                size="small"
                                fullWidth={true}
                                rules={{ 
                                    required: "Required."
                                }}
                                errors={errors}
                            >
                                <MenuItem value="">Please select</MenuItem>
                                    {
                                        options.provinces.map((option,i) => {
                                            return (
                                                <MenuItem value={option.abbreviation} key={i}>{option.name}</MenuItem>
                                            )
                                        })
                                    }
                            </Selector>
                        </div>
                        <div className="col-md-3 col-6">
                            <Controller
                                as={
                                    <TextField 
                                        label="Postal Code"
                                        autoComplete="new-password"
                                        fullWidth={true}
                                        size="small"
                                        variant="outlined"
                                        error={errors.postal_code && true}
                                        helperText={
                                            errors?.postal_code?.message
                                        }
                                        inputProps={{maxLength: 7}}
                                        className="mb-3"
                                    />
                                }
                                name="postal_code"
                                control={control}
                                defaultValue={""}
                                rules={{ 
                                    pattern: {
                                        value: /^[a-zA-Z0-9 ]+$/i,
                                        message: 'This field must contain only letters and numbers.'
                                    },
                                    required: "Required."
                                }}
                            />
                        </div>
                    </div>
                    <Selector
                        name="type"
                        className={"mb-3"}
                        label="Property Type"
                        control={control}
                        defaultValue={""}
                        variant="outlined"
                        size="small"
                        fullWidth={true}
                        rules={{ 
                            required: "This field is required."
                        }}
                        errors={errors}
                    >
                        <MenuItem value="">Please select</MenuItem>
                        <MenuItem value="house">House</MenuItem>
                        <MenuItem value="condo">Condominium</MenuItem>
                        <MenuItem value="townhouse">Townhouse</MenuItem>
                    </Selector>
                    <div className="d-flex align-items-center">
                        {
                            mode === "wizard" ? (
                                <Button 
                                    disabled={submitting}
                                    variant="outlined"
                                    color="primary"
                                    disableElevation
                                    className="me-3"
                                    size="small"
                                    type="submit">
                                    { wizard.state === 'NOT_STARTED' ? <>Start</> : <>Save & Continue</>}
                                </Button>
                            ) : (
                                <Button 
                                    disabled={submitting}
                                    variant="outlined"
                                    color="primary"
                                    disableElevation
                                    className="me-3"
                                    size="small"
                                    type="submit">
                                    Save Changes
                                </Button>
                            )
                        }
                        { submitting && <LinearProgress style={{width: '30px'}}/> }
                    </div>
                </form>
            </div>
        </div>
    )
}
export default Step0; 
import React, { useEffect, useState } from "react";
import { parseBackendErrors } from '../../helpers/parseBackendErrors';
import { updateProfile } from './../../helpers/user';
import { useSelector, useDispatch } from 'react-redux';
import { useSnackbar } from 'notistack';
import { Button,TextField,Typography} from '@material-ui/core';
import { useForm, Controller } from "react-hook-form";
import { updateProfile as updateProfileAction } from './../../store/actions/app.js';

const Profile = () => {

    // state
    const [submitting, setSubmitting] = useState(false);

    // redux
    const { app } = useSelector(state => state);
    const dispatch = useDispatch();

    // hooks
    const { control, handleSubmit, errors, setValue } = useForm();
    const { enqueueSnackbar } = useSnackbar();

    useEffect(() => {
        if(app.user.profile) {
            setValue("first_name", app.user.profile.first_name);
            setValue("last_name", app.user.profile.last_name);
        }
    },[]);

    // methods
    const handleProfileUpdate = async (form) => {
        setSubmitting(true);
        const [error, response] = await updateProfile(app.user._id,app.token,form);
        setSubmitting(false);
        if(error) {
            const parsed_errors = parseBackendErrors(error.response);
            enqueueSnackbar(parsed_errors, { 
                variant: 'error',
                anchorOrigin: {
                    vertical: 'top',
                    horizontal: 'center',
                }
            });
            return;
        }
        enqueueSnackbar('Success! Your profile has been updated.', { 
            variant: 'success',
            anchorOrigin: {
                vertical: 'top',
                horizontal: 'center',
            }
        });
        dispatch( updateProfileAction(response.data) );
    }

    return (
        <>
            <Typography variant="h6" className="mb-3">My Profile</Typography>
            <div className="row">
                <div className="col-xl-5 col-lg-8 col-md-8 col-sm-10 col-12">
                    <form onSubmit={handleSubmit(handleProfileUpdate)}> 
                        <Controller
                            as={
                                <TextField 
                                    label="First Name"
                                    autoComplete="new-password"
                                    fullWidth={true}
                                    variant="outlined"
                                    error={errors.first_name && true}
                                    helperText={
                                        errors?.first_name?.message
                                    }
                                    className="mb-3"
                                    inputProps={
                                        {maxLength: 30}
                                    }
                                />
                            }
                            name="first_name"
                            control={control}
                            defaultValue={""}
                            rules={{ 
                                required: "This field is required."
                            }}
                        />
                        <Controller
                            as={
                                <TextField 
                                    label="Last Name"
                                    autoComplete="new-password"
                                    fullWidth={true}
                                    variant="outlined"
                                    error={errors.last_name && true}
                                    helperText={
                                        errors?.last_name?.message
                                    }
                                    className="mb-3"
                                    inputProps={
                                        {maxLength: 30}
                                    }
                                />
                            }
                            name="last_name"
                            control={control}
                            defaultValue={""}
                            rules={{ 
                                required: "This field is required."
                            }}
                        />
                        <Button 
                            disabled={submitting}
                            size="large" 
                            variant="contained" 
                            color="primary" 
                            type="submit"
                            >Save
                        </Button>
                    </form>
                </div>
            </div>
        </>
    )
}
export default Profile; 
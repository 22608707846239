import React, { useEffect, useState } from "react";
import { 
    Card as CardMui,
    CardMedia,
    IconButton,
    TextField,
    Button
} from '@material-ui/core';
import classNames from "classnames";
import styles from './MediaLibrary.module.scss';
import { useForm, Controller } from "react-hook-form";
import { DeleteForever } from 'mdi-material-ui';

const Card = ({ photo, updateCaptionCallback, deletePhotoCallback, is_busy }) => {

    // misc hooks
    const { handleSubmit, control, errors, setValue  } = useForm();

    // lifecycles
    useEffect(() => {
        setValue("caption", photo.caption ? photo.caption : '');
    }, []);

    // methods
    const updateImage = (form) => {
        updateCaptionCallback({
            caption: form.caption,
            id: photo._id
        });
    }

    return (
        <CardMui className={classNames(styles['card'])}>
            <div className={classNames(styles['header'])}>
                <IconButton size="small" color="secondary" onClick={() => deletePhotoCallback(photo._id)} disabled={is_busy == photo._id}>
                    <DeleteForever/>
                </IconButton>
            </div>
            <CardMedia
                className={classNames(styles['media'])}
                image={photo?.formats?.thumbnail?.url}
            />
            <form onSubmit={handleSubmit(updateImage)} className={classNames(styles["card-meta"], 'w-100 d-flex align-items-center px-2 py-2')}>
                <Controller
                    as={
                        <TextField 
                            placeholder="Caption"
                            autoComplete="new-password"
                            fullWidth={true}
                            margin="dense"
                            size="small"
                            error={errors.caption && true}
                            helperText={ errors?.caption?.message }
                            inputProps={{maxLength: 60}}
                        />
                    }
                    name="caption"
                    control={control}
                    defaultValue={""}
                />
                <div className="ms-2">
                    <Button size="small" color="primary" type="submit" variant="outlined" disabled={is_busy == photo._id} style={{maxWidth:"50px"}}>
                        Save
                    </Button>
                </div>
            </form>
        </CardMui>
    )
}

export default Card;
import React, { useEffect, useState } from "react";
import { 
    Typography,
    LinearProgress
} from '@material-ui/core';
import { useSelector, useDispatch } from 'react-redux';
import { getPropertyById } from '../../helpers/property';
import classNames from "classnames";
import styles from './MediaLibrary.module.scss';
import Card from './Card';
import { deletePhoto, updatePhoto } from '../../helpers/property';
import { parseBackendErrors } from '../../helpers/parseBackendErrors';
import { useSnackbar } from 'notistack';
import Uploader from './Uploader';

const MediaLibrary = ({property_id}) => {

    // state
    const [photos, setPhotos] = useState([]);
    const [loading, setLoading] = useState(true);
    const [is_busy, setBusyItem] = useState(null);

    // redux
    const { app } = useSelector(state => state);
    const dispatch = useDispatch();

    // hooks
    const { enqueueSnackbar } = useSnackbar();

    // lifecycles
    useEffect(() => {
        handleGetPropertyById(property_id);
    }, []);

    // methods
    const handleGetPropertyById = async(id) => {
        const [error, response] = await getPropertyById(id);
        if(error) return;
        setPhotos(response.data?.photo.reverse());
        setLoading(false);
    }

    const handleUpdateCaption = async(data) => {
        const payload = {
            caption: data.caption
        };
        setBusyItem(data.id);
        const [error,] = await updatePhoto(payload,property_id,data.id,app.token);
        setBusyItem(null);
        if(error) {   
            const parsed_errors = parseBackendErrors(error.response);
            enqueueSnackbar(parsed_errors, { 
                variant: 'error',
                anchorOrigin: {
                    vertical: 'top',
                    horizontal: 'center',
                }
            });
            return;
        }
        enqueueSnackbar('This caption has been updated!', { 
            variant: 'success',
            anchorOrigin: {
                vertical: 'top',
                horizontal: 'center',
            }
        });
    }

    const handleDeletePhoto = async(photo_id) => {
        const confirm = window.confirm("Are you sure you want to delete this?");
        if(confirm) {
            setBusyItem(photo_id);
            const [error,] = await deletePhoto(property_id,photo_id,app.token);
            setBusyItem(null);
            if(error) {   
                const parsed_errors = parseBackendErrors(error.response);
                enqueueSnackbar(parsed_errors, { 
                    variant: 'error',
                    anchorOrigin: {
                        vertical: 'top',
                        horizontal: 'center',
                    }
                });
                return;
            }
            setPhotos(photos.filter(photo => photo._id !== photo_id));
            enqueueSnackbar('This photo has been deleted!', { 
                variant: 'success',
                anchorOrigin: {
                    vertical: 'top',
                    horizontal: 'center',
                }
            });
        }
    }

    const handleUploadImage = (data) => {
        setPhotos(prevArray => [...data,...prevArray])
    }

    if(loading) {
        return <LinearProgress/>
    }
    return (
        <>
            <Uploader
                property_id={property_id}
                imageUploadedCallback={(data) => handleUploadImage(data)}
            />
            {
                photos.length === 0 ? (
                    <div className={classNames(styles["empty-state"], 'd-flex align-items-center justify-content-center')}>
                        <Typography variant="caption">No photos of your property yet.</Typography>
                    </div>
                ) : (
                    <div className="row">
                        {
                            photos.map((photo) => {
                                return (
                                    <div className="col-md-3 col-6 mb-4" key={photo._id}>
                                        <Card 
                                            photo={photo} 
                                            updateCaptionCallback={(data) => handleUpdateCaption(data)}
                                            deletePhotoCallback={(data) => handleDeletePhoto(data)}
                                            is_busy={is_busy}
                                        />
                                    </div>
                                )
                            })
                        }
                    </div>
                )
            }
            
        </>
    )
}

export default MediaLibrary;
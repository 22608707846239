import axios from "axios";
import config from "../config";

export const create = async (data,property_id,token) => {
  try {
    let response = await axios({
      method: "post",
      url: `${config.SERVER_URL}/layouts/${property_id}`,
      headers: {
        'Authorization': 'Bearer ' + token
      },
      data: data
    });
    return [null, response];
  } catch (error) {
    return [error];
  }
};

export const find = async (property_id) => {
  try {
    let response = await axios({
      method: "get",
      url: `${config.SERVER_URL}/layouts/${property_id}`
    });
    return [null, response];
  } catch (error) {
    return [error];
  }
};

export const deleteLayout = async (property_id,layout_id,token) => {
  try {
    let response = await axios({
      method: "delete",
      url: `${config.SERVER_URL}/layouts/${property_id}/${layout_id}`,
      headers: {
        'Authorization': 'Bearer ' + token
      }
    });
    return [null, response];
  } catch (error) {
    return [error];
  }
};
import React, { useEffect, useState } from "react";
import { 
    Avatar,
    Button,
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    useMediaQuery,
    List,
    ListItem,
    ListItemText,
    ListItemAvatar,
    Paper
} from '@material-ui/core';
import { Alert, AlertTitle } from '@material-ui/lab';
import { useSelector } from 'react-redux';
import { OfficeBuilding, HomeVariant, HomeModern } from 'mdi-material-ui';
import classNames from "classnames";
import styles from './../Wizard.module.scss';
import { find } from '../../helpers/property';
import moment from 'moment';

const SavedProperty = ({handlePropertySelectCallback}) => {

    // state
    const [modal_opened, setModalOpened] = useState(false);
    const [loading, setLoading] = useState(true);
    const [properties, setProperties] = useState([]);

    // redux
    const { app } = useSelector(state => state);

    // hooks
    const is_small_screen = useMediaQuery('(max-width:600px)');

    useEffect(() => {
        async function handleFindProperties() {
            const query = `?user=${app.user._id}&status=DRAFT`;
            const [error, response] = await find(query);
            setLoading(false);
            if(error) return;
            setProperties(response.data);
        }
        handleFindProperties();
    },[]);

    // methods
    const GetPropertyIcon = ({type}) => {
        switch(type) {
            case 'house': 
                return <HomeVariant/>
            case 'condo': 
                return <OfficeBuilding/>
            case 'townhouse': 
                return <HomeModern/>
            default:
                return <HomeVariant/>;
        }
    }
    const select = (item) => {
        handlePropertySelectCallback(item);
        handleCancel()
    }
    const handleOpen = () => {
        setModalOpened(true);
    }
    const handleCancel = () => {
        setModalOpened(false);
    }
    if(loading) return null;
    if(!loading && properties.length === 0) return null;
    return (
        <>
            <Alert severity="info" className={classNames(styles['saved-property-widget'])}>
                <AlertTitle><strong>Attention!</strong></AlertTitle>
                You have a few unfinished properties. Do you want to select one now to continue?
                <Button 
                    className="mt-3 d-block"
                    variant="outlined" 
                    color="primary" 
                    size="small"
                    onClick={handleOpen}
                    >
                    Select
                </Button>
            </Alert>
            <Dialog
                disableBackdropClick
                disableEscapeKeyDown
                fullWidth={true}
                maxWidth="sm"
                open={modal_opened}
                fullScreen={is_small_screen}
                >
                    <DialogTitle>Jumpstart</DialogTitle>
                    <DialogContent dividers>
                    <Paper>
                        <List dense disablePadding>
                            {
                                properties?.map((item,index) => {
                                    return (
                                        <ListItem 
                                            onClick={() => select(item)} 
                                            key={item._id} 
                                            divider={index < properties.length - 1 ? true : false}
                                            button 
                                            style={{borderRadius:0}}
                                            >
                                            <ListItemAvatar>
                                                <Avatar style={{backgroundColor: '#2196F3'}}><GetPropertyIcon type={item.type}/></Avatar>
                                            </ListItemAvatar>
                                            <ListItemText primary={
                                                `${item.unit_number} ${item.street_number} ${item.street_name}`
                                            } secondary={moment.utc(item.createdAt).tz( moment.tz.guess() ).format('MMMM Do YYYY, h:mm:ss a')} />
                                        </ListItem>
                                    )
                                })
                            }
                        </List>
                    </Paper>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={handleCancel} color="primary" variant="outlined" size="small">
                            Cancel
                        </Button>
                    </DialogActions>
            </Dialog>
        </>
    )
}

export default SavedProperty;
import React, { useEffect, useState } from "react";
import { 
    Tabs,
    Tab,
    Paper,
    Typography,
    LinearProgress
} from '@material-ui/core';
import { useSelector, useDispatch } from 'react-redux';
import classNames from "classnames";
import styles from './PropertyEditor.module.scss';
import { useSnackbar } from 'notistack';
import options from '../../Wizard/options.json'
import { Step0, Step1, Step2, Step3, Step4, Step5, Step6 } from '../../Wizard/index';
import { update, getPropertyById } from './../../helpers/property';
import { parseBackendErrors } from '../../helpers/parseBackendErrors';
import { Restore, SetId, SetState, ResetWizard } from './../../store/actions/wizard.js';

const PropertyEditor = ({property_id}) => {

    // state
    const [loading, setLoading] = useState(false);
    const [submitting, setSubmitting] = useState(false);
    const [tab, setTab] = useState(0);
    const [is_error, setError] = useState(false);

    // redux
    const { wizard, app } = useSelector(state => state);
    const dispatch = useDispatch();

    // hooks
    const { enqueueSnackbar } = useSnackbar();

    // lifecycles
    useEffect(() => {
        handleGetPropertyById(property_id);
        return () => {
            dispatch(ResetWizard());
        };
    }, []);

    // methods
    const handleGetPropertyById = async(id) => {
        setLoading(true);
        const [error, response] = await getPropertyById(id);
        setLoading(false);
        if(error) {
            setError(true);
            return;
        };
        dispatch(Restore(response.data));
        dispatch(SetId(response.data._id));
        dispatch(SetState(response.data.status));
        setError(false);
        setLoading(false);
    }

    // methods
    const handleTabChange = (event, newValue) => {
        setTab(newValue);
    };

    const handleFormSubmit = async (form) => {
        setSubmitting(true);
        const [error,response] = await update(form,wizard._id,app.token);
        setSubmitting(false);
        if(error) {   
            const parsed_errors = parseBackendErrors(error.response);
            enqueueSnackbar(parsed_errors, { 
                variant: 'error',
                anchorOrigin: {
                    vertical: 'top',
                    horizontal: 'center',
                }
            });
            return;
        }
        enqueueSnackbar('Your property has been updated!', { 
            variant: 'success',
            anchorOrigin: {
                vertical: 'top',
                horizontal: 'center',
            }
        });
        dispatch(Restore(response.data));
    }

    const getStepContent = (step) => {
        switch (step) {
            case 0:
                return <Step0 mode="editor" submitting={submitting} handleStartCallback={(form) => handleFormSubmit(form)}/>
            case 1:
                return <Step1 mode="editor" submitting={submitting} handleBackCallback={(form) => handleFormSubmit(form)}  handleNextCallback={(form) => handleFormSubmit(form)}/>
            case 2:
                return <Step2 mode="editor" submitting={submitting} handleBackCallback={(form) => handleFormSubmit(form)}  handleNextCallback={(form) => handleFormSubmit(form)}/>
            case 3:
                return <Step3 mode="editor" submitting={submitting} handleBackCallback={(form) => handleFormSubmit(form)}  handleNextCallback={(form) => handleFormSubmit(form)}/>
            case 4:
                return <Step4 mode="editor" submitting={submitting} handleBackCallback={(form) => handleFormSubmit(form)}  handleNextCallback={(form) => handleFormSubmit(form)}/>
            case 5:
                return <Step5 mode="editor" submitting={submitting} handleBackCallback={(form) => handleFormSubmit(form)}  handleNextCallback={(form) => handleFormSubmit(form)}/>
            case 6:
                return <Step6 mode="editor" submitting={submitting} handleCompleteWizardCallback={(form) => handleFormSubmit(form)} handleBackCallback={(form) => handleFormSubmit(form)}/>
            default:
                return 'Unknown step';
        }
    }
    if(loading) {
        return (
            <LinearProgress/>
        )
    };
    if(is_error && !loading) {
        return (
            <div className="text-center">
                <Typography variant="caption">Error! Something went wrong.</Typography>
            </div>
        )
    }
    return (
        <div className={classNames(styles['property-editor'], '')}>
            <Paper>
                <Tabs
                    className="mb-3"
                    variant="scrollable"
                    scrollButtons="on"
                    value={tab}
                    onChange={handleTabChange}
                >
                    {
                        options.steps.map((label, index) => (
                            <Tab label={label} key={index}/>
                        ))
                    }
                </Tabs>
            </Paper>
            <div className="row">
                <div className="col-12 col-xl-6 col-md-9">
                    {getStepContent(tab)}
                </div>  
            </div>
        </div>
    )
}

export default PropertyEditor;
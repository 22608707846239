import React, { useEffect } from "react";
import { 
    Button,
    Typography,
    TextField,
    MenuItem,
    LinearProgress
} from '@material-ui/core';
import { useForm, Controller } from "react-hook-form";
import { useSelector } from 'react-redux';
import Selector from './../components/Selector';
import options from './options.json'

const Step3 = ({handleNextCallback, handleBackCallback, submitting, mode}) => {

    // misc hooks
    const { handleSubmit, control, errors, setValue, getValues, watch  } = useForm();

    // redux
    const { wizard } = useSelector(state => state);

    // methods
    const handleNextStep = async (form) => {
        handleNextCallback(form);
    }
    
    const watchFieldLocker = watch("locker", false); 

    const handleBackStep = async () => {
        const payload = {
            house_type: getValues("house_type"),
            house_style: getValues("house_style"),
            exterior: getValues("exterior"),
            garage_type: getValues("garage_type"),
            garage_amount: getValues("garage_amount"),
            driveway: getValues("driveway"),
            driveway_amount: getValues("driveway_amount"),
            locker: getValues("locker"),
            locker_number: getValues("locker_number"),
            total_parking_amount: getValues("total_parking_amount"),
            pool: getValues("pool")
        };
        handleBackCallback(payload);
    }

    useEffect(() => {
        setValue("house_type", wizard?.exterior?.house_type ? wizard?.exterior?.house_type : '');
        setValue("house_style", wizard?.exterior?.house_style ? wizard?.exterior?.house_style : '');
        setValue("exterior", wizard?.exterior?.exterior ? wizard?.exterior?.exterior : '');
        setValue("garage_type", wizard?.exterior?.garage_type ? wizard?.exterior?.garage_type : '');
        setValue("garage_amount", (wizard?.exterior?.garage_amount !== null && wizard?.exterior?.garage_amount !== undefined) ? wizard?.exterior?.garage_amount : '');
        setValue("driveway", wizard?.exterior?.driveway ? wizard?.exterior?.driveway : '');
        setValue("driveway_amount", (wizard?.exterior?.driveway_amount !== null && wizard?.exterior?.driveway_amount !== undefined) ? wizard?.exterior?.driveway_amount : '');
        setValue("locker", (wizard?.exterior?.locker !== null && wizard?.exterior?.locker !== undefined) ? wizard?.exterior?.locker : '');
        setTimeout(() => {
            setValue("locker_number", wizard?.exterior?.locker_number !== '' ? wizard?.exterior?.locker_number : '');
        });
        setValue("total_parking_amount", (wizard?.exterior?.total_parking_amount !== null && wizard?.exterior?.total_parking_amount !== undefined) ? wizard?.exterior?.total_parking_amount : '');
        setValue("pool", wizard?.exterior?.pool ? wizard?.exterior?.pool : '');
    },[setValue,wizard?.exterior]);

    return (
        <div className="row">
            <div className="col-12">
                <form onSubmit={handleSubmit(handleNextStep)} className="align-self-center w-100">
                    {
                        wizard.type !== "condo" && (
                            <>
                                <Selector
                                    name="house_type"
                                    className={"mb-3"}
                                    label="Type"
                                    control={control}
                                    defaultValue={""}
                                    variant="outlined"
                                    size="small"
                                    fullWidth={true}
                                    rules={{ 
                                        required: "This field is required." 
                                    }}
                                    errors={errors}
                                >
                                    <MenuItem value="">Please select</MenuItem>
                                    {
                                        options.exterior.house_type.map((option,i) => {
                                            return (
                                                <MenuItem value={option.field} key={i}>{option.value}</MenuItem>
                                            )
                                        })
                                    }
                                </Selector>
                                <Selector
                                    name="house_style"
                                    className={"mb-3"}
                                    label="Style"
                                    control={control}
                                    defaultValue={""}
                                    variant="outlined"
                                    size="small"
                                    fullWidth={true}
                                    rules={{ 
                                        required: "This field is required." 
                                    }}
                                    errors={errors}
                                >
                                    <MenuItem value="">Please select</MenuItem>
                                    {
                                        options.exterior.house_style.map((option,i) => {
                                            return (
                                                <MenuItem value={option.field} key={i}>{option.value}</MenuItem>
                                            )
                                        })
                                    }
                                </Selector>
                                <Selector
                                    name="exterior"
                                    className={"mb-3"}
                                    label="Exterior"
                                    control={control}
                                    defaultValue={""}
                                    variant="outlined"
                                    size="small"
                                    fullWidth={true}
                                    rules={{ 
                                        required: "This field is required." 
                                    }}
                                    errors={errors}
                                >
                                    <MenuItem value="">Please select</MenuItem>
                                    {
                                        options.exterior.exterior.map((option,i) => {
                                            return (
                                                <MenuItem value={option.field} key={i}>{option.value}</MenuItem>
                                            )
                                        })
                                    }
                                </Selector>
                            </>
                        )
                    }
                    {
                        wizard.type === "condo" && (
                            <>
                                <Selector
                                    name="locker"
                                    className={"mb-3"}
                                    label="Do you have a locker"
                                    control={control}
                                    defaultValue={""}
                                    variant="outlined"
                                    size="small"
                                    fullWidth={true}
                                    rules={{ 
                                        required: "This field is required." 
                                    }}
                                    errors={errors}
                                >
                                    <MenuItem value="">Please select</MenuItem>
                                    <MenuItem value="yes">Yes</MenuItem>
                                    <MenuItem value="no">No</MenuItem>
                                </Selector>
                                {
                                    watchFieldLocker === "yes" && (
                                        <Controller
                                            as={
                                                <TextField 
                                                    label="Locker number"
                                                    autoComplete="new-password"
                                                    fullWidth={true}
                                                    size="small"
                                                    variant="outlined"
                                                    error={errors.locker_number && true}
                                                    helperText={ errors?.locker_number?.message ? errors?.locker_number?.message : "This field is optional." }
                                                    className="mb-3"
                                                    inputProps={
                                                        {maxLength: 5}
                                                    }
                                                />
                                            }
                                            name="locker_number"
                                            control={control}
                                            defaultValue={""}
                                            rules={{ 
                                                //required: "This field is required."
                                            }}
                                        />
                                    )
                                }
                            </>
                        )
                    }
                    <Selector
                        name="pool"
                        className={"mb-2"}
                        label="Pool"
                        control={control}
                        defaultValue={""}
                        variant="outlined"
                        size="small"
                        fullWidth={true}
                        rules={{ 
                            required: "This field is required." 
                        }}
                        errors={errors}
                    >
                        <MenuItem value="">Please select</MenuItem>
                        {
                            options.exterior.pool.map((option,i) => {
                                return (
                                    <MenuItem value={option.field} key={i}>{option.value}</MenuItem>
                                )
                            })
                        }
                    </Selector>
                    
                    <Typography variant="overline" className="mb-2 d-block">Parking</Typography>
                    {
                        wizard.type !== "condo" && (
                            <>
                                <Selector
                                    name="garage_type"
                                    className={"mb-3"}
                                    label="Garage Type"
                                    control={control}
                                    defaultValue={""}
                                    variant="outlined"
                                    size="small"
                                    fullWidth={true}
                                    rules={{ 
                                        required: "This field is required." 
                                    }}
                                    errors={errors}
                                >
                                    <MenuItem value="">Please select</MenuItem>
                                    {
                                        options.exterior.garage_type.map((option,i) => {
                                            return (
                                                <MenuItem value={option.field} key={i}>{option.value}</MenuItem>
                                            )
                                        })
                                    }
                                </Selector>
                                <Controller
                                    as={
                                        <TextField 
                                            label="Total Garage Parking Spaces"
                                            type="number"
                                            autoComplete="new-password"
                                            fullWidth={true}
                                            size="small"
                                            variant="outlined"
                                            error={errors.garage_amount && true}
                                            helperText={ errors?.garage_amount?.message }
                                            className="mb-3"
                                            InputProps={{
                                                inputProps: { 
                                                    max: 1000, min: 0 
                                                }
                                            }}
                                        />
                                    }
                                    name="garage_amount"
                                    control={control}
                                    defaultValue={""}
                                    rules={{ 
                                        required: "This field is required."
                                    }}
                                />
                                <Selector
                                    name="driveway"
                                    className={"mb-3"}
                                    label="Driveway"
                                    control={control}
                                    defaultValue={""}
                                    variant="outlined"
                                    size="small"
                                    fullWidth={true}
                                    rules={{ 
                                        required: "This field is required."
                                    }}
                                    errors={errors}
                                >
                                    <MenuItem value="">Please select</MenuItem>
                                    {
                                        options.exterior.driveway.map((option,i) => {
                                            return (
                                                <MenuItem value={option.field} key={i}>{option.value}</MenuItem>
                                            )
                                        })
                                    }
                                </Selector>
                                <Controller
                                    as={
                                        <TextField 
                                            label="Total Driveway Parking Spaces"
                                            type="number"
                                            autoComplete="new-password"
                                            fullWidth={true}
                                            size="small"
                                            variant="outlined"
                                            error={errors.driveway_amount && true}
                                            helperText={ errors?.driveway_amount?.message }
                                            className="mb-3"
                                            InputProps={{
                                                inputProps: { 
                                                    max: 1000, min: 0 
                                                }
                                            }}
                                        />
                                    }
                                    name="driveway_amount"
                                    control={control}
                                    defaultValue={""}
                                    rules={{ 
                                        required: "This field is required."
                                    }}
                                />
                            </>
                        )
                    }
                    <Controller
                        as={
                            <TextField 
                                label="Number of Total Parking Spaces"
                                type="number"
                                autoComplete="new-password"
                                fullWidth={true}
                                size="small"
                                variant="outlined"
                                error={errors.total_parking_amount && true}
                                helperText={ errors?.total_parking_amount?.message }
                                className="mb-3"
                                InputProps={{
                                    inputProps: { 
                                        max: 1000, min: 0 
                                    }
                                }}
                            />
                        }
                        name="total_parking_amount"
                        control={control}
                        defaultValue={""}
                        rules={{ 
                            required: "This field is required."
                        }}
                    />
                    <div className="d-flex align-items-center">
                        {
                            mode === "wizard" ? (
                                <>
                                    <Button 
                                        disabled={submitting}
                                        variant="outlined"
                                        color="primary"
                                        disableElevation
                                        size="small"
                                        onClick={() => handleBackStep()}
                                        >
                                        Previous
                                    </Button>
                                    <Button 
                                        disabled={submitting}
                                        className="mx-3"
                                        variant="outlined"
                                        color="primary"
                                        disableElevation
                                        size="small"
                                        type="submit"
                                        >
                                        Save & Continue
                                    </Button>
                                </>
                            ) : (
                                <Button 
                                    disabled={submitting}
                                    variant="outlined"
                                    color="primary"
                                    disableElevation
                                    className="me-3"
                                    size="small"
                                    type="submit">
                                    Save Changes
                                </Button>
                            )
                        }
                        { submitting && <LinearProgress style={{width: '30px'}}/> }
                    </div>
                </form>
            </div>
        </div>
    )
}
export default Step3; 